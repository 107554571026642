import "./playGame.scss";
import React, { useEffect, useState } from "react";
import Layout from '../../../../../shared/components/layout';
import { Col, Row } from 'antd';
import Card from "../../../../../shared/components/card";
import GameDetails from "../../../shared/components/gameDetails";
import ShareGame from '../../../shared/components/shareGame';
import VotingDetails from "../../../shared/components/votingDetails";
import GameCompleted from "../../../shared/components/gameCompleted";
import { getQueryParams } from "../../../../../shared/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setSeletedGame } from "../../../../../shared/redux/game/gameSlice";
import Api from "../../../../../shared/api/Api";
import NoData from "../../../../../shared/components/noData";
import { getUser } from "../../../../../shared/redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../../../shared/components/spinner";

const PlayGame = () => {
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const navigate = useNavigate();
	const gameId = getQueryParams().get('gameId')
	const [game, setGame] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [updateDeleted, setUpdateDeleted] = useState(false)

	const handleBoyVoting = () => {
		if(!!game?.contest?.voting_ended){
			return 0
		}
		if (user?.id === game?.contest?.user_id) {
			return toast.error("You cannot vote on your own game", { position: "top-center" })
		}
		navigate("/play-game/voting-boy");
	}

	const handleGirlVoting = () => {
		if(!!game?.contest?.voting_ended){
			return 0
		}
		if (user?.id === game?.contest?.user_id) {
			return toast.error("You cannot vote on your own game", { position: "top-center" })
		}
		navigate("/play-game/voting-girl");
	}

	useEffect(() => {
		if (gameId) {
			setIsLoading(true)
			return Api.get(`/contests/${gameId}`, { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' } }).then(
				(res) => {
					if (res?.status == 200) {
						dispatch(setSeletedGame({ selectedGame: res?.data }))
						setGame(res?.data)
					}
					setIsLoading(false)
				}
			).catch((err) => {
				if(err?.response?.status === 404){
					setIsLoading(false)
					window.location.replace("https://www.genderrevealgame.villie.co/")
				}
			})
		}
	}, [gameId, updateDeleted])

	return (
		<>
			<Layout>
				<Card>
					{
						isLoading ?
							<Spinner />
							:
							game ?
								<Row gutter={[20,20]}>
									<Col s={24} sm={24} md={14} lg={15} xl={15}>
										<GameDetails game={game} user={user} uploadImage={false} setUpdateDeleted={() => setUpdateDeleted(!updateDeleted)}/>
									</Col>
									<Col xs={24} sm={24} md={10} lg={9} xl={9}>
										<Row gutter={[16, 30]}>
											<Col span={24}>
												{
													!!game?.contest?.closeout_complete ?
													<GameCompleted game={game}/>
													:
													<VotingDetails
														game={game}
														handleBoyVoting={handleBoyVoting}
														handleGirlVoting={handleGirlVoting}
													/>
													
												}
											</Col>
											{!game?.contest?.closeout_complete && <Col span={24}><ShareGame game={game}/></Col>}
										</Row>
									</Col>
								</Row>
							:
								<NoData
									image={"../assets/pink-bear.svg"}
									description={"No game exist's."}
									styles={{padding: "20% 0"}}
								/>
					}
				</Card>
			</Layout>
		</>

	);
};

export default PlayGame;
