import React, { useState } from "react";
import { Typography, Col, Row } from 'antd';
import Card from "../../../../../shared/components/card";
import Banner from "../../../../../shared/components/banner";
import Modal from "../../../../../shared/components/modal";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import Spinner from "../../../../../shared/components/spinner"
import './genderCard.scss';

const { Title, Paragraph } = Typography;

const GenderCard = ({game, handleChooseGender, isVisible, setIsVisible}) => {
	const [gender, setGender] = useState(null)

	return (
		<>
			<Card className="genderCard">
				<Paragraph className="title">Select the gender</Paragraph>
				<Row gutter={16}>
					{
						game?.contest?.baby_gender === 0 || game?.contest?.baby_gender === 1 ?
							<Col span={24}>
								<Card className="imageCard">
									<img src={game?.contest?.baby_gender == 0 ? "../assets/blue-bear.svg" : "../assets/pink-bear.svg"} alt="bear" />
									<br />
									<Paragraph className={game?.contest?.baby_gender == 0 ? "boy" : "girl"}>{game?.contest?.baby_gender == 0 ? "Boy" : "Girl"}</Paragraph>
								</Card>
							</Col>
						:
						<>
							<Col span={12}>
								<Card className="imageCard" action={
									() => {
										setGender("0")
										setIsVisible(true)
									}
								}>
									<img src="../assets/blue-bear.svg" alt="bear" />
									<br />
									<Paragraph className="boy">Boy</Paragraph>
								</Card>
							</Col>
							<Col span={12}>
								<Card className="imageCard" action={
									() => {
										setGender("1")
										setIsVisible(true)
									}
								}>
									<img src="../assets/pink-bear.svg" alt="bear" />
									<br />
									<Paragraph className="girl">Girl</Paragraph>
								</Card>
							</Col>
						</>
					}
				</Row>
				{ 
					game?.contest?.baby_gender === 0 || game?.contest?.baby_gender === 1 ? 
					<Title className="heading">Congratulations,<br />it’s a {game?.contest?.baby_gender == 0 ? "Boy" : "Girl"}!</Title> 
					:
					<Banner title={"The selection cannot be undone or overridden. Pay close attention"} type={"info"}/>
				}
				<Modal visible={isVisible} handleClose={() => setIsVisible(false)}>
					<div onClick={() => {
						handleChooseGender(gender)
					}}>
						<p className="title">Are You Sure?</p>
						<p className="description">
							Once you choose the gender, the game is over and cannot be replayed
						</p>
						<ButtonDefault primary>Yes, I’m Sure</ButtonDefault>
					</div>
				</Modal>
			</Card>
		</>
	)
}

export default GenderCard;