import "./styles.scss"
import React from 'react';
import { Form, DatePicker } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from "moment";

const CustomDatePicker = ({label, name, placeholder="dd/mm/yyyy", tooltip=false, required=false, errorMessage}) => {

    const rules = () => {
        const rule = []

        if(required){
            rule.push({ required: true, message: errorMessage })
        }

        return rule
    }

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
      }

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules()}
            className="formItem"
            tooltip={tooltip && {
                title: tooltip,
                icon: <InfoCircleFilled style={{color: "#463649"}}/>,
            }}

        >
            <DatePicker allowClear={false} format="MM-DD-YYYY" disabledDate={disabledDate} className='datePicker' placeholder={placeholder} />
        </Form.Item>
    );
}

export default CustomDatePicker;