import { createSlice } from '@reduxjs/toolkit';

export const gameSlice = createSlice({
    name: 'game',
    initialState: {
        selectedGame: null,
        votes: {for: null, noOfVotes: null},
        showModal: false
    },
    reducers: {
        setSeletedGame:(state,action) => {
            state.selectedGame = action.payload.selectedGame;
        },
        setVotes:(state, action) => {
            state.votes = action.payload.votes;
        },
        setShareModalVisibility:(state, action) => {
            state.showModal = action.payload.showModal;
        },
        resetGameState: (state) => {
            state.selectedGame = null
            state.votes = {for: null, noOfVotes: null}
        },
    },
});


export const getSeletedGame = (state) => state.game.selectedGame;
export const getVotes = (state) => state.game.votes;
export const getShareModalVisibility = (state) => state.game.showModal

export const { setSeletedGame, setVotes, resetGameState, setShareModalVisibility } = gameSlice.actions;

export default gameSlice.reducer;
