import FormPayment from "./FormPayment";
import { useSelector } from "react-redux";
import './Payment.scss';
import { getSeletedGame, getVotes } from "../../../../../shared/redux/game/gameSlice";
import { useEffect, useState } from "react";
import Api from "../../../../../shared/api/Api";
import { toast } from "react-toastify";
import Layout from "../../../../../shared/components/layout";
const Payment = () => {
	const selectedGame = useSelector(getSeletedGame)
	const votes = useSelector(getVotes)
	const [fee, setFee] = useState(null)

	useEffect(() => {
		Api.get(`/payment/calc_payment?contestId=${selectedGame?.contest?.id}&numVotes=${votes?.noOfVotes}`).then(
			(res) => {
				setFee(res?.data)
			}).catch((err) => {
				toast("There is an error, try again later", { position: "top-center" })
			});

	}, [])

	return (
		<Layout>
			<div className="paymentContainer">
				<div style={{ width: '100%'}}>
					<button style={{cursor: "pointer"}} onClick={() => { window.history.back() }}><img src="../assets/arrow-left.png" alt="<" /></button>
				</div>
				<div className="Summary box">
					<h1>{selectedGame?.contest?.name}</h1>
					<h2>{selectedGame?.contest?.parent_1_first_name} {selectedGame?.contest?.parent_1_last_name} & {selectedGame?.contest?.parent_2_first_name} {selectedGame?.contest?.parent_2_last_name}</h2>
					<p>{selectedGame?.contest?.city}, {selectedGame?.contest?.country}</p>
					<h3>Let’s Tally Your Total!</h3>
					<ul>
						<li>{selectedGame?.contest?.parent_1_first_name} {selectedGame?.contest?.parent_1_last_name} & {selectedGame?.parent_2_first_name} {selectedGame?.parent_2_last_name}</li>
						<li><label>Number of Plays</label><span>{votes?.noOfVotes}</span></li>
						<li><label>Price Per Play</label><span>${selectedGame?.contest?.min_cost_per_vote}</span></li>
						<li><hr /></li>
						<li><label>Your Support</label><strong>${parseFloat(fee?.final_cost) - parseFloat(fee?.service_fee)}</strong></li>
						<li><label>Fees</label><span>${fee?.service_fee}</span></li>
						<li><hr /></li>
						<li><label>Grand Total</label><span>${fee?.final_cost}</span></li>
					</ul>
				</div>
				<FormPayment votes={votes} contestId={selectedGame?.contest?.id} total={fee?.final_cost}/>
			</div>
		</Layout>
	);
};

export default Payment;