import './gender-reveal.css'
import React from 'react'
import { Helmet } from 'react-helmet'
import SweetAlert from 'react-bootstrap-sweetalert'
import GenderMap from './gender.json'
import Api from "../../shared/api/Api";



class GenderPredictor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dateTime: new Date(),
      age: 18,
      month: new Date().getMonth(),
      day: 0,
      year: new Date().getFullYear(),
      email: '',
      name: '',
      showResult: false,
    }
    this.allowedAge = []
    this.months = [
      'Jan',
      'Feb',
      'Mar',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    let age = 18
    while (age <= 45) {
      this.allowedAge.push(age)
      age += 1
    }

    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    fetch('http://worldclockapi.com/api/json/utc/now')
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          dateTime: new Date(data.currentDateTime),
          month: new Date(data.currentDateTime).getMonth(),
          year: new Date(data.currentDateTime).getFullYear(),
        })
      })
      .catch(() => {

      })
  }

  onDateTimeUpdate(data) {
    const date = {
      month: this.state.month,
      day: this.state.day,
      year: this.state.year,
      ...data,
    }
    if (new Date(date.year, date.month, date.day).getDate() !== Number(date.day)) {
      const lastDay = new Date(date.year, Number(date.month) + 1, 0).getDate()
      date.day = `${lastDay}`
    }

    this.setState({
      month: date.month,
      day: date.day,
      year: date.year,
      dateTime: new Date(date.year, date.month, date.day),
    })
  }

  submitForm () {
    // submit data to backend to save inputted data
    Api.post(`/predictor`,
        {
            "email": this.state.email,
            "dateTime": this.state.dateTime,
            "age": this.state.age,
            "name": this.state.name
          },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }).then(() => {
        // show result
        this.setState({
          showResult: true,
        })
      })
  }

  render() {
    const { state } = this
    const lastDay = new Date(state.year, Number(state.month) + 1, 0).getDate()
    const days = []
    let counter = 1
    while (counter <= lastDay) {
      days.push(counter)
      counter += 1
    }
    return (
      <div className="layout">
        <Helmet>
          <script src="https://unpkg.com/@lottiefiles/lottie-player@0.4.0/dist/tgs-player.js" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        </Helmet>

        <SweetAlert
          show={state.showResult}
          title={<h5 style={{ margin: '0', fontWeight: '600' }}>Congratulations!</h5>}
          onConfirm={() => {
            this.setState({
              showResult: false,
            })
          }}
          onCancel={() => {
            this.setState({
              showResult: false,
            })
          }}
          showConfirm
        >
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            // padding: '4em',
          }}
          >
            <lottie-player
              autoplay
              // controls
              // loop
              mode="normal"
              src="https://assets3.lottiefiles.com/packages/lf20_C72Bko.json"
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
            />
          </div>

          <h4 style={{
            margin: '0 0 .8em',
            fontWeight: '700',
          }}
          >It's a {GenderMap[`${state.age}`][this.months[state.month]] === 'F' ? 'Girl' : 'Boy'}
          </h4>
        </SweetAlert>
        <section className="main-content">
          <div className="banner">
            <img src={`../assets/pregnant-woman.png`} alt="ExpectantMom" />
          </div>
          <div className="content">
            <div className="content-head">
              <img src={`../assets/logo.png`} alt="logo" />
              <h2 className="hero">is it a girl or a boy?</h2>
            </div>

            <div className="form-container">
              <div className="form">
                <h5 className="legend">Legend says the ancient Chinese gender chart, found in a royal tomb 700+ years ago, can predict a baby’s gender. Ready for your prediction?</h5>

                <div className="input-row">
                  <p className="label">How old were you when you conceived?</p>
                  <div className="fields one">
                    <select
                      className="data-input"
                      value={state.age}
                      onChange={(e) => {
                        this.setState({
                          age: e.target.value,
                        })
                      }}
                    >
                      {this.allowedAge.map((age) => <option value={age}>{age}</option>)}
                    </select>
                  </div>
                </div>

                <div className="input-row ">
                  <p className="label">What’s your due date?</p>
                  <div className="fields two">
                    <select
                      className="data-input"
                      placeholder="MM"
                      value={state.month}
                      onChange={(e) => {
                        this.onDateTimeUpdate({
                          month: e.target.value,
                        })
                      }}
                    >
                      {this.months.map((month, index) => <option value={index}>{month}</option>)}
                    </select>
                    <select
                      className="data-input"
                      placeholder="DD"
                      value={state.day}
                      onChange={(e) => {
                        this.onDateTimeUpdate({
                          day: e.target.value,
                        })
                      }}
                    >
                      {days.map((day) => <option value={day}>{day}</option>)}
                    </select>
                    <select
                      className="data-input"
                      onChange={(e) => {
                        this.onDateTimeUpdate({
                          year: e.target.value,
                        })
                      }}
                    >
                      {[
                        this.state.dateTime.getFullYear(),
                        this.state.dateTime.getFullYear() + 1,
                      ].map((year) => <option value={year}>{year}</option>)}
                    </select>
                  </div>
                </div>

                <div className="input-row ">
                  <p className="label">Your email?</p>
                  <div className="fields three">
                    <input
                      className="data-input"
                      placeholder="i_love_cute@babies.com"
                      value={state.email}
                      onChange={(e) => {
                        this.setState({
                          email: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="input-row ">
                  <p className="label">Your name?</p>
                  <div className="fields four">
                    <input
                      className="data-input"
                      placeholder="Expecting Parent"
                      value={state.name}
                      onChange={(e) => {
                        this.setState({
                          name: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="submit-btn"
                  onClick={this.submitForm}
                >PREDICT BABY’S GENDER
                </button>

                <p className="terms-and-policy">
                  By registering, you confirm that you are 16 years of age or older, and you agree to our <a href="https://genderrevealgame.villie.co/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</a> & <a href="https://genderrevealgame.villie.co/privacy-policy " target="_blank" rel="noopener noreferrer">Privacy Policy</a>. We use your information to send you emails, product samples, and promotions, and may share your information with partners.
                </p>

                <h5 className="about-chart">
                  <i>The Chinese gender chart isn’t scientifically based. At the very least, it’s very entertaining!</i>
                </h5>
              </div>
            </div>

          </div>
        </section>

        <section className="bottom-content">
          <div className="block-1">
            <p>This tool is brought to you by:</p>
            <div>
              <p>THE GENDER REVEAL GAME</p>
              <p>by Villie Inc.</p>
            </div>
          </div>

          <div className="block-2">
            <h3>
              <b>Connect with us</b>
            </h3>
            <div>
              <a
                className="gender-reveal-icon-instagram link-item"
                target="_blank"
                href="https://www.instagram.com/genderrevealgame/"
                rel="noreferrer"
              />

              <a
                className="fa fa-pinterest-square link-item"
                href="https://www.pinterest.com/genderrevealapp"
                target="_blank"
                rel="noreferrer"
              />
              <a
                className="fa fa-facebook-square link-item"
                href="https://www.facebook.com/GenderRevealGame/"
                target="_blank"
                rel="noreferrer"
              />

            </div>
          </div>

          <div className="block-3">
            <h5>About Gender Reveal Game</h5>
          </div>

          <div className="block-4">
            <p>This predictor is designed for educational purposes only. Consult with a medical professional if you have health concerns. Use of this site is subject to our <a href="https://genderrevealgame.com/terms">Terms of Use</a> and <a href="https://genderrevealgame.com/privacy-policy">Privacy Policy</a>.</p>
          </div>
        </section>
      </div>

    )
  }
}

export default GenderPredictor
