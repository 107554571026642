import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import InputField from "../../../../../components/InputField/InputField";
import { getSeletedGame } from "../../../../../shared/redux/game/gameSlice";
import { checkEmail } from "../../../../../shared/utils/checkEmail";
import { checkPhone } from "../../../../../shared/utils/checkPhone";
import {useNavigate} from "react-router-dom";
import "./success.scss";

const Success = () => {
  const navigate = useNavigate();
  const game = useSelector(getSeletedGame)
  const initialStateData = { email: "", phone: "" };
  const [preferences, setPreferences] = useState(initialStateData);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (checkEmail(preferences) && checkPhone(preferences)) {
      setPreferences(initialStateData);
      toast.success("Preferences saved successfully");
    }
  };

  return (
    <div className="Success container">
      <div className="box">
        <img
          src="../assets/bears.png"
          alt="Bears"
          width="162.7px"
          height="135.5px"
        />
        <h1>Awesome! </h1>
        <h1>Yay! You just played the gender reveal game!</h1>
        <h3>Get updates</h3>
        <form onSubmit={handleSubmit}>
          <InputField
            value={preferences.email}
            onChange={(val) => {
              setPreferences({ ...preferences, email: val.value });
            }}
            name="Enter Email Address"
            required
          />
          <InputField
            value={preferences.phone}
            type="tel"
            onChange={(val) => {
              setPreferences({ ...preferences, phone: val.value });
            }}
            name="Phone Number"
            required
          />
          <ButtonDefault primary className="prefrenceBtn">Count Me In!</ButtonDefault>
          <br />
          <ButtonDefault className="backToGame" secondary 
            onClick={() => {
              navigate(`/play-game?gameId=${game?.contest?.id}`);
            }} >
              Back To Game
            </ButtonDefault>
        </form>
      </div>
    </div>
  );
};

export default Success;
