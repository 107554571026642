import React, { useState } from 'react';
import Layout from '../../../../../shared/components/layout';
import { useNavigate, Link } from "react-router-dom";
import VoteButtons from "../../../shared/components/VoteButtons";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { getSeletedGame, getVotes, setVotes } from '../../../../../shared/redux/game/gameSlice';
import { useSelector, useDispatch } from "react-redux";
import Card from '../../../../../shared/components/card';
import './voting.scss';

const VotingBoy = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const game = useSelector(getSeletedGame);
	const votes = useSelector(getVotes)
	const [vote, setVote] = useState(
		votes?.for == 'boy' && votes?.noOfVotes !== null ? votes?.noOfVotes : 1
	);

	const handlePayment = () => {
		dispatch(setVotes({ votes: { for: "boy", noOfVotes: vote } }))
		navigate("/play-game/payment")
	}

	return (
		<Layout>
			<Card>
				<div className="VotingBoy">
					<Link to={`/play-game?gameId=${game?.contest?.id}`} className="backBtn"><img src="../assets/arrow-left.png" alt="<" /></Link>
					<h1>{game?.contest?.name}</h1>
					<div className="sub-heading">
						<strong>It’s a Boy! </strong>
						{/* <img src="../assets/boy.png" alt="" /> */}
					</div>
					<VoteButtons price={game?.contest?.min_cost_per_vote} vote={vote} setVote={setVote} gender={"boy"} />
					<ButtonDefault primary onClick={handlePayment}>Let’s Get This Party Started!</ButtonDefault>
					<p className='terms'>By Continuing You Agree To Our <a href="https://www.genderrevealgame.villie.co/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
				</div>
			</Card>
		</Layout>
	)
};

export default VotingBoy;