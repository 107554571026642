import React, { useEffect, useState } from 'react';
import BackgroundHearts from '../../../../shared/components/backgroundHearts';
import ButtonDefault from '../../../../components/ButtonDefault/ButtonDefault.js';
import './confirmation.scss';
import { useNavigate } from "react-router-dom";
import Api from '../../../../shared/api/Api';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../../../shared/redux/user/userSlice.js';
import { Footer } from '../../../../components/Footer/Footer';
import { setSeletedGame, setShareModalVisibility } from '../../../../shared/redux/game/gameSlice.js';
import { Col, Row } from 'antd';
import GamesSlider from '../../components/gamesSlider';


const Confirmation = () => {
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const navigate = useNavigate();
	const [games, setGames] = useState(null);
	const [continueGame, setContinueGame] = useState(false)

	useEffect(() => {
		getUserGames();
	}, [])

	const getUserGames = async () => {
		const myGamesResponse = Api.get(`/my_contests/?id=${user.id}`).then(
			(res) => {
				return res;
			}).catch((err) => {
				if (err.response.status === 401) {
					sessionStorage.clear()
					localStorage.clear()
				}
			});
		const myGames = await myGamesResponse;
		setGames(myGames.data);
	}

	const handleEnterGame = (game) => {
		dispatch(setSeletedGame({selectedGame: {"contest": game}}))
		dispatch(setShareModalVisibility({ showModal: true }))
		setContinueGame(false)
		navigate('/dashboard')
	}

	useEffect(() => {
		if(games){
			const url = new URL(window.location.href);
			const fromNavbar = url.searchParams.get("navbar");
			if(!!fromNavbar){
				if(games.length > 0){
					setContinueGame(true)
				}
			}
		}
	}, [games])

	return (
		<BackgroundHearts>
			<div className="confirmation-screen">
				<div className="frame">
					<Row>
						{ 
							!continueGame && 
							<Col span={24}>
								<img src="../assets/bears.png" alt="" />
							</Col>
						}
						<Col span={24}>
							{
								continueGame? 
								<h1>Choose One Of The<br/>Current Game</h1>
								:
								<h1>Cue the Confetti. <br/>It’s Time To Party!</h1>
							}
						</Col>
						<Col span={24}><p>Hey parents, here’s a tip!<br/>
							Research shows that parents who share their game at least once a day for the first week raise 400% more than parents who don’t! <br/>
							</p></Col>

						{ 
							!continueGame ?
							<Col span={24} className="btns">
								{ games?.length > 0 && <ButtonDefault className="continueBtn" primary onClick={() => setContinueGame(true)}>Continue Game</ButtonDefault>}
								<ButtonDefault primary={!(games?.length > 0)} onClick={() => {navigate('/create-game')}}>Create A New Game</ButtonDefault>
							</Col>
							:
							<GamesSlider games={games} handleEnterGame={handleEnterGame}/>
						}
						<Col span={24} className="footer">
							<Footer />
						</Col>
					</Row>
				</div>
			</div>
		</BackgroundHearts>
	)
}

export default Confirmation;