import React, {useEffect} from "react";
import BackgroundHearts from "../../../../components/BackgroundHearts.js";
import ButtonDefault from "../../../../components/ButtonDefault/ButtonDefault.js";
import RadioField from "../../../../components/RadioField/RadioField.js";
import "./register.scss";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAccessToken, setAccessToken } from "../../../../shared/redux/auth/authSlice.js";
import { setUser } from "../../../../shared/redux/user/userSlice.js";
import Api from "../../../../shared/api/Api.js";
import { toast } from "react-toastify";
import classNames from 'classnames';
import Spinner from "../../../../shared/components/spinner/index.jsx";
import useWindowSize from "../../../../shared/hooks/use-window-size.js";
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input } from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';

const Register = () => {
	const [terms, setTerms] = useState(false)
	const accesstoken = useSelector(getAccessToken)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({})
	const [isLoading, setIsloading] = useState(false)
	const { width } = useWindowSize()

	useEffect(() => {
		if (accesstoken) {
			navigate("/confirmation")
		}
	}, [])


	const handleRegister = (values) => {
		if(values?.password?.trim() === ""){
			return setErrors({ password: "Please also add at least one character other than space" })
		}
		setIsloading(true)
		return Api.post(`/registrations`, {
			"user": {
				"email": values.email,
				"password": values.password,
			}
		},
			{
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers': 'Content-Type'
				}
			}).then((res) => {
				setIsloading(false)
				if (res.data.email) {
					const { authentication_token, } = res.data

					dispatch(setAccessToken({ accessToken: authentication_token }))
					dispatch(setUser({ user: res.data }))

					navigate("/confirmation");
				} else {
					toast.error(res.message, { position: "bottom-right" });
					return { success: false, reason: res.message }
				}
			}).catch((err) => {
				setIsloading(false)
				if (err.response.data.message.includes("User email already exists.")) {
					setErrors({ accountExists: "Hey! We already have your email on file. Login to get back into your account" })
				}
				toast.error(err.response.data.message, { position: "bottom-right" });
				return { success: false, reason: err.response.data.message }
			})
	}

	const handleError = () => {
        if(errors){
            setErrors(null)
        }
    }

	return (
		<BackgroundHearts className="bg-register" bg="#d6daff" hearts="#B7BEFD">
			<div className="register-screen">
				<div className="frame">
					<div className="information">
						<div className="contain">
							<a href="https://www.genderrevealgame.villie.co/"  target="_blank" rel="noopener noreferrer">
								<img src={`../assets/logo.png`} alt="logo" />
							</a>
							<h1>Let's Start Your Game</h1>
							<ul className="list">
								<li className="item-list">
									Why not turn a Gender Reveal into a fun, competitive game?{" "}
								</li>
								<li className="item-list">
									Raise money for a bundle of joy by having friends and family
								</li>
								<li className="item-list">
									One player wins a prize. Funds raised goes to expecting
									parent(s)
								</li>
							</ul>
						</div>
						{
							width > 450 &&
							<img
								className="couple-img"
								src={"../assets/register.jpg "}
								alt="couple"
							/>
						}
					</div>
					<div className="register">
						<Form
                            name="register"
                            layout="vertical"
                            style={{ maxWidth: '353px'}}
                            onFinish={handleRegister}
                        >
							<p className="details">
								For the purpose of gamers regulation, your details are required.
							</p>

							<Form.Item
                                label={"Email address"}
                                name={"email"}
                                rules={[
									{required: true, type: "email", message: "* Email not valid."}
								]}
                                className="formItem"
                            >
                                <Input className='input' prefix={<UserOutlined />} placeholder={"Enter email address"} onChange={handleError}/>
                            </Form.Item>

							<Form.Item
                                label="Create a password"
                                name="password"
                                className="formItem"
                                rules={[
									{
										required: true,
										min: 8,
										message: "Please make sure your password is at least 8 characters."
									},
								]}
                            >
                                <Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter password" onChange={handleError}/>
                            </Form.Item>
							{!!errors?.password && <div className="error-msg"> *  {errors?.password}</div>}
							<Form.Item
                                label="Enter password again"
                                name="repeatPassword"
                                className="formItem"
                                rules={[
									{
									  required: true,
									  message: 'Please make sure your passwords match.',
									},
									({ getFieldValue }) => ({
									  validator(_, value) {
										if (!value || getFieldValue('password') === value) {
										  return Promise.resolve();
										}
										return Promise.reject(new Error('Please make sure your passwords match.'));
									  },
									}),
								]}
                            >
                                <Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter password again" onChange={handleError}/>
                            </Form.Item>

							<RadioField
								id="r-terms"
								value={terms}
								label={
									<div>
										<span>I agree to the </span>
										<a target='_blank' href='https://www.genderrevealgame.villie.co/terms-and-conditions'><u>terms and conditions</u></a>
									</div>
								}
								onClick={() => {
									setErrors({})
									setTerms(!terms);
								}}
								checked={terms}
							/>

							<Form.Item>
								<ButtonDefault
									disabled={!terms}
									type="submit"
									className={classNames({ 'primaryDisabled': !terms })}
									primary
								>
									{isLoading ? <Spinner forBtn={true} /> : "Register My Account"}
								</ButtonDefault>
                            </Form.Item>

							<div style={{ textAlign: "center" }}>
								<p className="details">
									Already Have An Account? <Link to="/login" style={{ textDecoration: "underline" }}>Login Here</Link>
								</p>
								{
									!!errors?.accountExists &&
									<p className="details">
										{errors?.accountExists} <Link to="/forgot-password">Forgot Your Password?</Link>
									</p>
								}
							</div>
						</Form>
					</div>
				</div>
			</div>
		</BackgroundHearts>
	);
};

export default Register