import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./ButtonLink.scss";

const ButtonLink = ({ text, link, customClass='button-link', fontSize="17px" }) => {
  return <Link style={{ fontSize: fontSize}} className={customClass} to={link} > {text} </Link>;
};

ButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  customClass: PropTypes.string,
};

export default ButtonLink;
