import React, { useEffect, useState } from "react";
import BackgroundHearts from "../../../../components/BackgroundHearts.js";
import ButtonDefault from '../../../../components/ButtonDefault/ButtonDefault.js';
import './login.scss';
import { Link, useNavigate } from 'react-router-dom';
import Api from "../../../../shared/api/Api.js";
import { useSelector, useDispatch } from 'react-redux';
import { getAccessToken, setAccessToken } from "../../../../shared/redux/auth/authSlice.js";
import { setUser } from "../../../../shared/redux/user/userSlice.js";
import Spinner from "../../../../shared/components/spinner/index.jsx";
import useWindowSize from "../../../../shared/hooks/use-window-size.js";
import { Form, Input } from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';

const Login = () => {
    const [form] = Form.useForm();
    const accesstoken = useSelector(getAccessToken)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null)
    const { width } = useWindowSize()

    useEffect(() => {
        if (accesstoken) {
            navigate("/confirmation")
        }
    }, [])

    const handleLogin = (values) => {
        setIsLoading(true)
        return Api.post(`/sessions`, {
            "email": values.email,
            "password": values.password,
        },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Content-Type'
                }
            }).then((res) => {
                setIsLoading(false)
                if (res.data.email) {

                    const { authentication_token, } = res.data

                    dispatch(setAccessToken({ accessToken: authentication_token }))
                    dispatch(setUser({ user: res.data }))

                    navigate("/confirmation");
                } else {
                    return { success: false, reason: res.message }
                }
            }).catch((err) => {
                setIsLoading(false)
                if (err.response.data.message === 'Email address not found.') {
                    return setErrors({ email: 'Uh oh. Looks like we don’t have this email on file. Why don’t you try a different email or create a new account' });
                }
                if (err.response.data.message === 'Password is incorrect.') {
                    return setErrors({ password: 'Oops! That didn’t work. Let’s try a different password' });
                }
            })
    };

    const handleError = () => {
        if(errors){
            setErrors(null)
        }
    }

    return (
        <BackgroundHearts className="bg-register" bg="#d6daff" hearts="#B7BEFD">
            <div className="login-screen">
                <div className="frame" style={{ borderRadius: width <= 450 && "0" }}>
                    <div className="login">
                        <a href="https://www.genderrevealgame.villie.co/" target="_blank" rel="noopener noreferrer">
                            <img className="logo" src={`../assets/logo.png`} alt="logo" />
                        </a>
                        <h1>Let’s Get This Party Started!</h1>
                        <Form
                            form={form}
                            name="login"
                            layout="vertical"
                            style={{ maxWidth: '353px'}}
                            onFinish={handleLogin}
                        >
                            <Form.Item
                                label={"Email address"}
                                name={"email"}
                                rules={[{required: true, type: "email", message: "* Please enter a valid email address"}]}
                                className="formItem"
                            >
                                <Input className='input' placeholder={"Enter email address"} prefix={<UserOutlined />} onChange={handleError}/>
                            </Form.Item>
                            {!!errors?.email && <div className="error-msg"> *  {errors?.email}</div>}

                            <Form.Item
                                label="Password"
                                name="password"
                                className="formItem"
                                rules={[{required: true, message: '* Wrong password!'}]}
                            >
                                
                                <Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter password"  onChange={handleError}/>
                            </Form.Item>
                            {!!errors?.password && <div className="error-msg"> *  {errors?.password}</div>}

                            <Form.Item>
                                <ButtonDefault primary type="submit">
                                    {isLoading ? <Spinner forBtn={true} /> : "Login To Account"}
                                </ButtonDefault>

                            </Form.Item>
                        </Form>
                        <div style={{ textAlign: 'center' }} className="bottomDiv">
                            <Link to="/forgot-password" style={{ textDecoration: "underline"}}>Forgot Your Password?</Link>
                            <p>Don’t have an account? <Link to="/register" style={{ textDecoration: "underline", }}>Register Here</Link></p>
                        </div>
                        <div style={{ textAlign: 'center' }} className="bottomDiv">
                            <a href="https://www.villie.co/contact" style={{ textDecoration: "underline"}} target="_blank" rel="noopener noreferrer">Previous Logged In or Signed Up with Facebook? <br/> Contact Us for Support</a>
                        </div>
                    </div>
                    {width > 450 &&
                        <div className="information">
                            <img
                                className="couple-img"
                                src={"../assets/login-couple-kiss.png"}
                                alt="couple"
                            />
                        </div>
                    }

                </div>
            </div>
        </BackgroundHearts>
    );
};

export default Login