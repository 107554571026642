import axios from 'axios'
import {app} from "../config";

// http://genderrevealgame-test.us-east-1.elasticbeanstalk.com
export default axios.create({
    baseURL: app.API_BASE_URL
})
/*
   Getters and Setters for local storage items
 */

export function setAccessToken(token) {
    localStorage.setItem('authentication_token', token)
}
export const getAccessToken = () => {
    return localStorage.getItem('authentication_token')
}
export const getUserEmail = () => {
    return localStorage.getItem('user_email')
}
export function storeUserEmail(email) {
    localStorage.setItem('user_email', email)
}

// eslint-disable-next-line
export function setUserID(id) {
    localStorage.setItem('user_id', id)
}

export const getUserID = () => {
    return localStorage.getItem('user_id')
}

export function setGameID(id) {
    sessionStorage.setItem('game_id', id)
}
export const getGameID = () => {
    return sessionStorage.getItem('game_id')
}


// eslint-disable-next-line
export function setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
}
