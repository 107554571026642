import React, {useEffect, useState} from "react";
import { Col, Row } from 'antd';
import {PayPalButtons,usePayPalScriptReducer, FUNDING} from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {getSeletedGame} from "../../redux/game/gameSlice";
import Api from "../../api/Api";

const Paypal = ({handlePayment, value, vote}) => {
    const navigate = useNavigate()
    const selectedGame = useSelector(getSeletedGame)
	const [{ isPending }] = usePayPalScriptReducer();
    const [amount, setAmount] = useState({total_cost : value})
    const paypalbuttonTransactionProps = {
        fundingSource: FUNDING.PAYPAL,
        style: { layout: "vertical" },
        createOrder(data, actions) {
          return actions.order.create({
            application_context: {
                brand_name: 'Gender Reveal Game',
                user_action: 'PAY_NOW'
            },
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: amount?.total_cost
                    },
                    description: "",
                    custom_id: selectedGame?.contest?.id,
                    soft_descriptor: 'vote_gender',
                }
            ]
          });
        },
        onApprove(data, actions) {
            return actions.order.capture().then(async function (details) {
                if (details.status === 'COMPLETED') {
                    const response = await handlePayment({paypal_id: details.id, voter_first_name: details.payer.name.given_name, voter_last_name: details.payer.name.surname, email: details.payer.email_address})
                    if(response.success){
                        navigate("/play-game/success")
                    }
                    else{
                        toast.error(response.message, {position: "top-center"})
                    }
                }
            })
        }
      };
    useEffect(() => {
        if(!amount?.total_cost)
        Api.get(`/payment/calc_payment?contestId=${selectedGame?.contest?.id}&numVotes=${vote?.noOfVotes}`).then(
            (res) => {
                setAmount(res?.data)
            }).catch((err) => {
            toast("There is an error, try again later", { position: "top-center" })
        });

    }, [])
	return (
		<>	
			<Row>
				<Col span={24}>
                    {isPending ? <p>Loading paypal button</p> : null}
                    <PayPalButtons {...paypalbuttonTransactionProps} />
				</Col>
			</Row>
		</>
	);
};

export default Paypal;