import React, { useEffect, useState } from "react";
import { STATES, PROVIDENCES } from "../../../../../shared/utils/lists";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { Col, Row } from 'antd';
import Input from "../../../../../shared/components/input";
import CustomSelect from "../../../../../shared/components/select";

const YourInformationStep = ({form}) => {

    const [country, setCountry] = useState(null)


    useEffect(() => {
        setCountry(form.getFieldValue("country"))
    }, [])

    return (
        <>
            <h2>Let’s Start with the Basics!</h2>
            <Row gutter={[100, 20]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent One First Name"
                        name="parent_one_first_name"
                        placeholder="Enter parent one first name"
                        required
                        errorMessage={"* Required information is missing."}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent One Last Name"
                        name="parent_one_last_name"
                        placeholder="Enter parent one last name"
                        required
                        errorMessage={"* Required information is missing."}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent Two First Name"
                        name="parent_two_first_name"
                        placeholder="Enter parent two first name"
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent Two Last Name"
                        name="parent_two_last_name"
                        placeholder="Enter parent two last name"
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                        label="Country"
                        name="country"
                        placeholder="Select"
                        required
                        errorMessage={"* Required information is missing."}
                        options={[
                            { value: 'Canada', label: 'Canada' },
                            { value: 'USA', label: 'USA' },
                        ]}
                        onChange={(value) => {setCountry(value);form.resetFields(["state_or_province"])}}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="City"
                        name="city"
                        placeholder="Enter city"
                        required
                        errorMessage={"* Required information is missing."}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                        label="State or Province"
                        name="state_or_province"
                        placeholder="Select"
                        required
                        errorMessage={"* Required information is missing."}
                        options={
                            !country ?
                            []
                            :
                            country === "USA" ? STATES : PROVIDENCES
                        }

                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Phone Number"
                        name="phone_number"
                        placeholder="Enter phone number"
                        required
                        errorMessage={"* Required information is missing."}
                        minLength={7}
                    />
                </Col>
                <Col xs={0} sm={0} md={12} lg={12} xl={12}></Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ButtonDefault
                        primary={true}
                        next={true}
                        type="submit"
                        className="infoStepBtn"
                    >
                        Next
                    </ButtonDefault>
                </Col>
            </Row>
        </>
    )
};

export default YourInformationStep;