import { BrowserRouter, Routes, Route } from "react-router-dom";
import {FacebookProvider} from "react-facebook/lib";

import Login from "../modules/auth/pages/login";
import NotFound from "../modules/other/pages/notFound";
import Register from "../modules/auth/pages/registerScreen";
import ForgotPassword from "../modules/auth/pages/forgotPassword/forgotPassword";
import CreateNewPassword from "../modules/auth/pages/forgotPassword/createNewPassword";
import Confirmation from "../modules/other/pages/confirmation"
import CreateGame from "../modules/games/host/page/createGame";
import { DashboardRouter } from "./DashboardRouter";
import { PlayGameRouter } from "./PlayGameRouter";
import ProtectedRoute from '../shared/http/protectedRoutes';
import GenderPredictor from "../modules/predictor/GenderPredictor";
import LiveGamesPage from "../modules/other/pages/liveGamesPage";

export const AppRouter = () => {
  return (
      <FacebookProvider appId="1111927622279359">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/live-games" element={<LiveGamesPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/create-new-password" element={<CreateNewPassword />} />
            <Route path="/confirmation" element={<ProtectedRoute><Confirmation /></ProtectedRoute>} />
            <Route path="/create-game" element={<ProtectedRoute to="signup"><CreateGame /></ProtectedRoute>} />
            <Route path="/dashboard/*" element={<ProtectedRoute><DashboardRouter /></ProtectedRoute>} />
            <Route path="/play-game/*" element={<PlayGameRouter />} />
            <Route path="/predictor" element={<GenderPredictor/>}/>
            <Route path="/*" element={<NotFound/>}/>
          </Routes>
        </BrowserRouter>
      </FacebookProvider>
  );
};
