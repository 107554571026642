import React, { useState, useEffect } from "react";
import { FlagFilled, EnvironmentFilled, LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Button, Card, Select, Typography, Image, Alert } from 'antd';
import Navbar from "../../../../components/Navbar/Navbar";
import './styles.scss';
import Api from "../../../../shared/api/Api"
import { daysLeft } from "../../../../shared/utils/helper"
import classNames from 'classnames';
import Spinner from "../../../../shared/components/spinner";
import { NavLink } from "react-router-dom";
const { Title, Paragraph } = Typography;


const LiveGamesPage = () => {
    const [games, setGames] = useState([])
    const [filter, setFilter] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [pagination, setPagnation] = useState({ currentPage: 1, totalPages: 0, perPage: 20 })

    const loadMore = () => {
        setLoadMoreLoading(true)
        setPagnation({ ...pagination, currentPage: pagination.currentPage + 1 })
    }

    const onChange = (value) => {
        setFilter(value)
    }

    useEffect(() => {
        if (!loadMoreLoading) {
            setLoading(true)
        }
        Api.get(`/contests?num_results=${pagination.currentPage == 0 ? pagination.perPage : pagination.currentPage * pagination.perPage}&offset=0&filter=${filter}`, { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' } }).then(
            (res) => {
                setLoading(false)
                setLoadMoreLoading(false)
                setPagnation({ ...pagination, totalPages: res?.data?.pages })
                setGames(res?.data?.contests)
            }
        ).catch((err) => {
            setLoading(false)
            setLoadMoreLoading(false)
        })
    }, [filter, pagination.currentPage])

    return (
        <>
            <Navbar />
            <div className="landingPage">
                <Alert message={<>Millions of dollars raised by parents across the country for their little ones. <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => window.open('https://www.genderrevealgame.villie.co/how-it-works', '_blank')}>Learn more here</span></>} type="success" className="banner"/>
                <Title className="title" level={5}>Select a game and see how it did.</Title>

                <Row className="top" justify="end">
                    <Col>
                        <Select defaultValue="all" onChange={onChange}>
                            <Select.Option value="all">All Games</Select.Option>
                            <Select.Option value="in-progress">Active Games</Select.Option>
                            <Select.Option value="completed">Completed Games</Select.Option>
                        </Select>
                    </Col>
                </Row>

                {
                    isLoading ?
                        <Spinner />
                        :
                        <>
                            <Row className="bottom" gutter={[20, 70]}>
                                {
                                    games?.length > 0 && games?.map((game, i) => {
                                        return (
                                            <Col key={i} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                                <NavLink to={`play-game?gameId=${game?.id}`} >
                                                    <Card
                                                        className="campaignCard"
                                                        hoverable
                                                        bodyStyle={{ padding: 0 }}
                                                    >
                                                        <Row className="campaignCardImg">
                                                            <div className="imgWrapper">
                                                                <Image
                                                                    src={game?.photos[0] ? game?.photos[0]?.img_url : "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"}
                                                                    alt={game?.name}
                                                                    quality={100}
                                                                    layout="fill"
                                                                    objectFit="cover"
                                                                    preview={false}
                                                                />
                                                            </div>
                                                            <div className={classNames("campaignTag", { "campaignSuccessfulTag": true })}>
                                                                <FlagFilled translate="true" style={{ marginBottom: 3, marginRight: 5 }} />
                                                                <span>{game?.winning_vote_id ? "Completed" : "Active"}</span>
                                                            </div>
                                                        </Row>
                                                        <Row className="campaignCardBody">
                                                            <Row className="campaignHeadingWrapper">
                                                                <Col span={24} className="title">
                                                                    <Title level={5}>{game?.name}</Title>
                                                                </Col>
                                                                <Col span={24} className="description">
                                                                    <Paragraph><EnvironmentFilled translate="true" /> {`${game?.city}, ${game?.country}`}</Paragraph>
                                                                    {/* <Paragraph className="campaignSummary"><span dangerouslySetInnerHTML={{ __html: game?.description }} /></Paragraph> */}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <div style={{ background: game?.tot == 0 ? "#2E2E2E" : "#463649", width: "100%", fontWeight: "900", borderRadius: "50px", marginTop: "2%", height: "26px" }}>
                                                                        {
                                                                            game?.tot == 0 ?
                                                                                <span style={{ fontFamily: "Poppins", fontSize: "14px", color: "#FFFFFF", marginLeft: "10px", marginTop: "1%" }}>{`0%`}</span>
                                                                                :
                                                                                <div style={{ background: "#463649", borderRadius: "50px", textAlign: "center", height: "25px", width: `${55}%` }}>
                                                                                    <span style={{ fontFamily: "Poppins", fontSize: "14px", color: "#FFFFFF", float: "right", marginRight: "5px", marginTop: "1%" }}>$ {game?.tot}</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="campaignDetailWrapper">
                                                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className="left">
                                                                    <Paragraph>Per Vote Price: ${game?.min_cost_per_vote?.toLocaleString()}</Paragraph>
                                                                    <Paragraph>Plays: {game?.tot / game?.min_cost_per_vote}</Paragraph>
                                                                    <Paragraph>Prize: {/^\d+$/.test(game?.prize_name) ? `$${game?.prize_name}` : game?.prize_name}</Paragraph>
                                                                    <Paragraph>Time Left: {daysLeft(game?.finish_date)} days</Paragraph>
                                                                </Col>
                                                                {/* <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8} className="right">
                                                        <Paragraph style={{ color: 'white' }}>.</Paragraph>
                                                        <Paragraph style={{ color: 'white' }}>.</Paragraph>
                                                        <Paragraph className="interest" style={{}}>6%</Paragraph>
                                                        <Paragraph>Interest Rate</Paragraph>
                                                    </Col> */}
                                                            </Row>
                                                        </Row>
                                                    </Card>
                                                </NavLink>
                                            </Col>
                                        )
                                    })

                                }

                            </Row>

                            {
                                games?.length > 0 && pagination.currentPage < pagination.totalPages &&
                                <Row justify="center">
                                    <Col><Button className="loadMore" onClick={loadMore} disabled={loadMoreLoading}>{loadMoreLoading ? <LoadingOutlined style={{ fontSize: 20, color: "#CC8DFF" }} spin /> : "Load more"}</Button></Col>
                                </Row>
                            }

                        </>
                }

            </div>
        </>
    )
}

export default LiveGamesPage;