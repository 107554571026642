import React, {useState} from "react";
import { Col, Row, Button } from 'antd';
import GameCard from '../../../../shared/components/gameCard';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import classNames from 'classnames';
import './gamesSlider.scss';
import useWindowSize from "../../../../shared/hooks/use-window-size";
import { useEffect } from "react";

const GamesSlider = ({ games, handleEnterGame, limit=3}) => {
	const { width } = useWindowSize()
	const [params, setParams] = useState({limit: limit, start: 0})

	const handleNext = () => {
		width <= 450 ?
		setParams({...params, start: params.start + 1})
		:
		setParams({...params, start: params.start + params.limit})
	}

	const handlePrev = () => {
		width <= 450 ?
		setParams({...params, start: params.start - 1})
		:
		setParams({...params, start: params.start - params.limit})


	}

	return (
		<>
			<div className="games">
				{
					width <= 450 ?
					games?.slice(params.start, params.start + 1)?.map((game, i) =>
						(<GameCard key={i} game={game} handleEnterGame={() => handleEnterGame(game)} />)
					)
					:
					games?.slice(params.start, params.start + params.limit)?.map((game, i) =>
						(<GameCard key={i} game={game} handleEnterGame={() => handleEnterGame(game)} />)
					)
				}
			</div>

			<Col span={24} className="scrollsBtns">
				
				{
					width <= 450 ?
					<>
						<Button
							className={classNames('leftBtn', { disable: params.start == 0 }, { active: !(params.start == 0) })}
							icon={<CaretLeftFilled />}
							onClick={handlePrev}
							disabled={params.start == 0}
						/>
						<Button
							className={classNames('rightBtn', { active: params.start + 1 < games?.length }, { disable: !(params.start + 1 < games?.length) })}
							icon={<CaretRightFilled />}
							onClick={handleNext}
							disabled={params.start + 1 >= games?.length}
						/>
					</>
					:
					<>
						<Button
							className={classNames('leftBtn', { disable: params.start == 0 }, { active: !(params.start == 0) })}
							icon={<CaretLeftFilled />}
							onClick={handlePrev}
							disabled={params.start == 0}
						/>
						<Button
							className={classNames('rightBtn', { active: params.start + 3 < games?.length }, { disable: !(params.start + 3 < games?.length) })}
							icon={<CaretRightFilled />}
							onClick={handleNext}
							disabled={params.start + 3 >= games?.length}
						/>
					</>
				}
			</Col>

			{
				width <= 450 && 
				<Col span={24} className="bottomCouter">
					{games?.map((game, i) => <div key={i} className={classNames({"grayCircle": i != params.start}, {"pinkCirlce": i == params.start})}></div>)}
				</Col>
			}
		</>
	)
}

export default GamesSlider;