import "./votingDetails.scss";
import React, { useEffect } from "react";
import PieChart from "../../../../../shared/components/pieChart"
import { calculateVoterPercentage } from "../../../../../shared/utils/helper";
import classNames from 'classnames';
import { useState } from "react";
import { getGender } from "../../../../../shared/utils/helper";
const VotingDetails = ({ game, handleBoyVoting, handleGirlVoting }) => {
	const [votesCount, setVotesCount] = useState(null)
	const createdAt = new Date(game?.contest?.created_at)
	const launchAt = new Date('2022-11-01')
	const [votes, setVotes] = useState([])

	useEffect(() => {
		if(createdAt < launchAt){
			if(game?.vote_counts){
				setVotes([...game?.vote_counts])
			}
			if(game?.summary){
				setVotesCount({boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes})
			}
		}
		else{
			if(game?.vote_counts){
				setVotes([{num_votes: 1, voter_first_name: "Kim", voter_last_name: "at Villie"}, ...game?.vote_counts])
			}
			if(game?.summary){
				const temp = getGender(game?.summary?.contest_name) == 0 ? {boy: game?.summary?.boy_votes + 1, girl: game?.summary?.girl_votes} : {boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes + 1}
				setVotesCount(temp)
			}
		}
		
	}, [game])

	return (
		<div className="votingDetails">
			<div className="information-sidebar">
				<img
					className="decoration"
					src="../assets/pink-bear.svg"
					alt="decoration"
				/>
				<p className="title">{!!(game?.contest?.voting_ended)? "Voting is closed":"Ready to Play!?"}</p>
				<div className="buttons">
					<div className={classNames("vote-button", {'voteBtnDisabled': !!(game?.contest?.voting_ended)})} 
						onClick={!(game?.contest?.voting_ended) && handleBoyVoting}
					>
						<img src={"../assets/blue-bear.svg"} alt="icon-btn" />
						<span style={{ color: "#5263FF" }}>It’s a Boy!</span>
					</div>
					<div className={classNames("vote-button", {'voteBtnDisabled': !!(game?.contest?.voting_ended)})} 
						onClick={!(game?.contest?.voting_ended) && handleGirlVoting}
					>
						<img src={"../assets/pink-bear.svg"} alt="icon-btn" />
						<span style={{ color: "#CC8DFF" }}>It’s a Girl!</span>
					</div>
				</div>
				{ 	
					votes?.length != 0 &&
					<div className="data">
					<p className="header">
						<span>Player</span>
						<span>Votes</span>
					</p>
					<div>
						<ul className="list">
							{votes?.map((vote, index) => {
								return (
									<li key={"list-" + index} className="item-list">
										<div>
											#{index + 1} <span className="name">
												{`${vote?.voter_first_name} ${vote?.voter_last_name}`}
											</span>
											<img className="genderImage" src={
										index == 0 ?
										getGender(game?.contest?.name) == 0 ? "../assets/blue-bear.svg" : "../assets/pink-bear.svg" :
										game?.contest?.baby_gender == 0 ? "../assets/blue-bear.svg" : "../assets/pink-bear.svg"
										} alt="icon-btn" />
										</div>
										<span className="amount">{vote?.num_votes}</span>
									</li>
								);
							})}
						</ul>
					</div>
					</div>
				}
				<div className="percentaje-details" style={{textAlign: votesCount?.length === 0 && "center", justifyContent: votesCount?.length === 0 && "center"}}>
					{
						!(votesCount?.boy === 0 && votesCount?.girl === 0) &&
						<div className="chart">
							<PieChart data={[
								{ name: "Girl", value: calculateVoterPercentage(votesCount?.boy, votesCount?.girl, "girl"), color: "#D9ABFE" },
								{ name: "Boy", value: calculateVoterPercentage(votesCount?.boy, votesCount?.girl, "boy"), color: "#5263FF" },
							]}/>
						</div>
					}
					<div className="detailsBox">
						<p className="title">Money Raised</p>
						<p className="amount">${createdAt < launchAt ? game?.summary?.money_raised : game?.summary?.money_raised + game?.contest?.min_cost_per_vote}</p>
						<p className="title">Game Prize</p>
						<p className="amount">{game?.contest?.prize_name}</p>
					</div>
				</div>
			</div>
		</div>
	);
};


export default VotingDetails;
