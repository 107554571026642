import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export const Footer = ({style}) => {
  return (
    <footer style={{...style}}>
      <div className="container">
        All Rights Reserved 2022 ® Gender Reveal Game | <a href="https://www.genderrevealgame.villie.co/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href="https://www.genderrevealgame.villie.co/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms &
        Conditions</a>
      </div>
    </footer>
  );
};
