import "./layout.scss";
import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import Navbar from "../../../components/Navbar/Navbar";
import { Footer } from "../../../components/Footer/Footer";

const Layout = ({ children }) => {

    const showFooter = () => {
        if (window.location.href.includes("/edit-game")) {
            return false
        }
        else {
            return true
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <Row >
                <Col span={24} className="gray">
                    <Navbar />
                    <Row className="layoutContainer" justify="center">
                        <Col span={24}>
                            {children}
                        </Col>
                    </Row>
                    {
                        showFooter() &&
                        <Footer />
                    }
                </Col>
            </Row>
        </>
    )
}


export default Layout;