import React, { useState } from "react";
import { Alert, Tooltip } from 'antd';
import "./donationDetails.scss";
import { useEffect } from "react";
import { LockOutlined, InfoCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import {getGender} from "../../../../../shared/utils/helper"
const DonationDetails = ({ game, title = "", tooltipText = "" }) => {
	const createdAt = new Date(game?.contest?.created_at)
	const launchAt = new Date('2022-11-01')
	const [votes, setVotes] = useState([])

	useEffect(() => {
		if(createdAt < launchAt){
			if(game?.vote_counts){
				setVotes([...game?.vote_counts])
			}
		}
		else{
			if(game?.vote_counts){
				setVotes([{num_votes: 1, voter_first_name: "Kim", voter_last_name: "at Villie"}, ...game?.vote_counts])
			}
		}
		
	}, [game])

	return (
		<>
		<div className="show-raised">
			{!(createdAt < launchAt) && 
			<Alert 
				className="banner" 
				message={votes?.length < 4 ? `Guess what? We made your first play of $ ${game?.contest?.min_cost_per_vote}! To keep it, get 3 more plays.` : `Cha-Ching! you've raised $ ${game?.contest?.min_cost_per_vote} on us. Congrats!`} 
				type="warning" 
			/>}

			<div className="title">
				<span>{title}</span>
				<span className="info">
					<img src="../assets/info-circle.svg" alt="icon-info" />
					<div className="tooltip-raised">
						<img src="../assets/coup.svg" alt="icon-info" />
						<div className="details">
							<p className="title">{tooltipText}</p>
							<p className="price">{createdAt < launchAt ? game?.summary?.money_raised : game?.summary?.money_raised + game?.contest?.min_cost_per_vote}</p>
						</div>
					</div>
				</span>
			</div>

			<p className="total">${createdAt < launchAt ? game?.summary?.money_raised : game?.summary?.money_raised + game?.contest?.min_cost_per_vote}.00</p>

			<ul className="list">
				{votes?.map((vote, index) => {
					return (
						<li key={"list-" + index} className="item-list">
							<div>
								{!(createdAt < launchAt) && index === 0? votes?.length < 4 ? <LockOutlined className="locked"/> : <UnlockOutlined /> : `#${index+1}`} 
								<span className={classNames("name", {"locked": !(createdAt < launchAt) && index === 0 && votes?.length < 4})}>
									{`${vote?.voter_first_name} ${vote?.voter_last_name}`}
									&nbsp;&nbsp;&nbsp;{!(createdAt < launchAt) && index === 0 && 
										<Tooltip placement="top" title={votes?.length < 4 ? "For keeping this you need to get 3 more plays." : "Congratulations, now this money is yours."}>
											<InfoCircleOutlined style={{cursor: "pointer", marginRight: "8px"}}/>
										</Tooltip>
									}
									<img className="genderImage" src={
										!(createdAt < launchAt) && index == 0 ?
										getGender(game?.contest?.name) == 0 ? "../assets/blue-bear.svg" : "../assets/pink-bear.svg" :
										game?.contest?.baby_gender == 0 ? "../assets/blue-bear.svg" : "../assets/pink-bear.svg"
										} alt="icon-btn" />
								</span>
							</div>
							<span className={classNames("amount", {"locked": !(createdAt < launchAt) && index === 0 && votes.length < 4})}>{vote?.num_votes}</span>
						</li>
					);
				})}
			</ul>
		</div>
		</>
	);
};

export default DonationDetails;