import "./gameCard.scss";
import React from 'react';
import { Row, Col } from 'antd';
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";
import { daysLeft } from "../../utils/helper.js";

const GameCard = ({game, handleEnterGame}) => {

    const getImagePlaceholder = () => {
        const images = ['../assets/blue-heart.png', '../assets/pink-heart.png']
        const colors = ['blue', 'pink']

        const random = Math.floor(Math.random() * images.length);

        return (<div className={`imageCircle ${colors[random]}`}>
            <img src={images[random]} />
        </div>)
    }

    return(
        <>
            <div className="gameCard">
                <Col span={24} className="top">
                    <Row>
                        <Col span={8}>
                            {getImagePlaceholder()}
                        </Col>
                        <Col span={16} className='right'>
                            <div><p className='daysLeft'>DAYS LEFT</p></div>
                            <div><p className="days">{daysLeft(game?.finish_date)}</p></div>
                        </Col>

                    </Row>
                    
                </Col>
                <Col span={24}>
                    <p className='title'>{game?.name}</p>
                </Col>
                <Col span={24}>
                    <ButtonDefault primary className='btn' 
                        onClick={handleEnterGame}>
                            Enter The Game
                    </ButtonDefault>
                </Col>
            </div>
        </>
    )
}


export default GameCard;