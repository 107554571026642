import React from "react";
import "./styles.scss";
import {
	WhatsappShareButton,
	TwitterShareButton,

	WhatsappIcon,
	TwitterIcon,
} from "react-share";

const ShareBtns = ({game, url}) => {
	return (
		<div className="show-raised">
			<div className="title">
				<span>{game?.summary?.money_raised == 0 ? "No plays yet! Start sharing to start raising." : "Start sharing to start raising"}</span>
			</div>
			<div className="socialIconsBox">
				<WhatsappShareButton url={url} title={"Play my Gender Reveal Game! Guess if we are having a Boy or girl with a $20 play. One winner who guess right will win a prize! Play now"}>
					<WhatsappIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
				</WhatsappShareButton>
				<TwitterShareButton url={url} hashtag={"#GenderReveal"} title={"Play my Gender Reveal Game! Guess if we are having a Boy or girl with a $20 play. One winner who guess right will win a prize! Play now"}>
					<TwitterIcon size={"2.5rem"} round={true} />
				</TwitterShareButton>
			</div>


		</div>
	);
};

export default ShareBtns;