import "./gameDetails.scss";
import React, { useState } from "react";
import Slider from "react-slick";
import { SliderArrowLeft } from "../../../../../components/SliderArrowLeft/SliderArrowLeft";
import { SliderArrowRight } from "../../../../../components/SliderArrowRight/SliderArrowRight";
import { useNavigate } from "react-router-dom";
import { DeleteFilled } from '@ant-design/icons';
import Modal from "../../../../../shared/components/modal";
import Spinner from "../../../../../shared/components/spinner";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { useSelector } from 'react-redux';
import { getAccessToken } from "../../../../../shared/redux/auth/authSlice";
import Api from "../../../../../shared/api/Api";
import {toast} from "react-toastify";
import useWindowSize from "../../../../../shared/hooks/use-window-size"
const GameDetails = ({ game, user, setUpdateDeleted, uploadImage = true }) => {
	const navigate = useNavigate();
	const [visible, setVisibile] = useState(false);
	const [selectedUpdate, setSelectedUpdate] = useState({id: null, text: null})
	const [isLoading, setIsLoading] = useState(false);
	const token = useSelector(getAccessToken)
	const {width} = useWindowSize()
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SliderArrowRight />,
		prevArrow: <SliderArrowLeft />,
	};

	const deleteUpdate = async (id) => {
		setIsLoading(true)
		await handleDelete(id)
		setIsLoading(false)
		setVisibile(!visible);
	};

	const handleDelete = (id) => {
		return Api.delete(`/contest_updates/${id}`,
			{
				headers: {
					'X-User-Token': `${token}`,
					'X-User-Email': `${user?.email}`
				}
			}).then((res) => {
				setUpdateDeleted()
				return {
					success: true,
					data: res.data
				}
			}).catch((err) => {
				if(err.response.statusText === "Not Found"){
					toast.error("Update doesn't exists", {position: "top-center"})
				}
				return {
					success: false,
					message: err.response.data.message
				}
			})
	}

	return (
		<section className="game-host">
			<h1 className="title">{game?.contest?.name}</h1>
			<Slider {...settings}>
				{game?.contest?.photos && game?.contest?.photos?.map((img) => {
					return (
						<div key={img}>
							<img className="main-image" src={img?.img_url} alt="" />
						</div>
					);
				})}
			</Slider>
			{game?.contest?.photos?.length === 0 &&
				(uploadImage ?
					<div onClick={() => navigate(`/dashboard/edit-game?gameId=${game?.contest?.id}&uploadImage=true`)}>
						<img className="placeHolderImage" src={width <= 450 ? "../assets/profilecover-mobile.png" : "../assets/profileCover.png"} alt="ProfileImage" />
					</div>
					:
					<div>
						<img className="placeHolderImage" style={{ cursor: "default" }} src={"../assets/player-view-placeholder.png"} alt="ProfileImage" />
					</div>)
			}
			<div className="details">
				<h2 className="sub-text">{game?.contest?.parent_1_first_name + ' ' + game?.contest?.parent_1_last_name} {game?.contest?.parent_2_first_name ? (' & ' + game?.contest?.parent_2_first_name + ' ' + (!!game?.contest?.parent_2_last_name ? game?.contest?.parent_2_last_name : "")) : null} </h2>
				<p className="city-text">{game?.contest?.city}, {game?.contest?.country}</p>

				<p className="description">{game?.contest?.description}</p>
				{game?.contest?.contest_updates?.length > 0 &&
					<div className="babyUpdates">
						<h2 className="sub-text">Game Updates</h2>
						<div className="updatesDetailsBox">
							{game?.contest?.contest_updates?.map((contest_update, index) => {
								return (
									<>
										<p className="updateDetails" id={index}>
											<img className="initialIcon" src="./assets/bell.svg" /> &nbsp;&nbsp;
											{contest_update.message} &nbsp;&nbsp;&nbsp;
											{user?.id === game?.contest?.user_id && <DeleteFilled onClick={() => {setVisibile(true);setSelectedUpdate({id: contest_update?.id, text: contest_update?.message})}} className="deleteIcon" />}
										</p>
									</>
								);
							})
							}
						</div>
					</div>}
			</div>
			<Modal visible={visible && !!selectedUpdate.id} handleClose={() => setVisibile(false)}>
				<div style={{ textAlign: "center" }}>
					<p className="title">Delete Updates?</p>
					<p className="description">
						Are you sure you want to delete this update? <br/>{`"${selectedUpdate.text}"`}.
					</p>
					<ButtonDefault
						onClick={() => deleteUpdate(selectedUpdate.id)}
						danger
					>
						{isLoading ? <Spinner forBtn={true} /> : "Yes, Delete This Update"}
					</ButtonDefault>
				</div>
			</Modal>
		</section>
	);
};

export default GameDetails;
