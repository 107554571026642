import React, {useEffect, useState} from "react";
import UploadImages from "../../../../../components/UploadImages/UploadImages";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { tooltips } from "../../../../../shared/utils/constants";
import { Col, Row } from 'antd';
import {useNavigate} from "react-router-dom";
import Spinner from "../../../../../shared/components/spinner";
import { stopEventPropagation } from "../../../../../shared/utils/helper";
import Input from "../../../../../shared/components/input";
import DatePicker from "../../../../../shared/components/datePicker"
import TextArea from "../../../../../shared/components/textArea"
import DonationBtns from "./../../components/donationBtn"

const GameInfoStep = ({form, data={}, isLoading, donationMoney, setDonationMoney, onChange = () => {}}) => {
    const navigate = useNavigate()

    useEffect(() => {
        if(data?.donation_per_vote === 10){
            delete data['donation_per_vote']
        }
        if(data?.donation_per_vote === 15){
            delete data['donation_per_vote']
        }
        if(data?.donation_per_vote === 20){
            delete data['donation_per_vote']
        }
        form.setFieldsValue(data)
    }, [data])
    
    return (
        <>
            <h2>Game Info</h2>
            <Row className="full"  justify="space-between" gutter={[12, 0]}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    
                    <Row gutter={[0, 26]}>
                        <Col span={24}>
                            <Input
                                label="Game Name"
                                name="game_name"
                                placeholder="Enter game name"
                                required
                                errorMessage={"* Not so fast! We can’t continue until you choose a game name."}
                                tooltip={tooltips.gameName.text}
                            />

                        </Col>
                        <Col span={24}>
                            <Input
                                label="Game Prize"
                                name="game_prize"
                                placeholder="Enter game prize"
                                required
                                errorMessage={"* Oh no! We can’t play until you make a choice. Please select your game prize!"}
                                tooltip={tooltips.contestPrize.text}
                            />
                        </Col>
                        <Col span={24}>
                            <DatePicker
                                label="Contest End Date"
                                name="contest_end_date"
                                required
                                errorMessage={"* Don’t forget to enter your contest end date!"}
                                tooltip={tooltips.contestEndDate.text}
                            />

                        </Col>
                        <Col span={24}>
                            {
                                data?.vote_counts == 0 ?
                                    <DonationBtns
                                        tooltip={tooltips.costPerVote.text}
                                        setDonationMoney={setDonationMoney}
                                        donationMoney={donationMoney}
                                        form={form}
                                        forEditGame
                                    />
                                    :
                                    <>
                                        <p className="votingLabel"><span className="required">*</span>Donation Per Vote</p>

                                        <ButtonDefault primary={"true"}
                                            className="votingBtn"
                                            disabled={true}
                                            onClick={(e) => stopEventPropagation(e)}
                                        >
                                            $ {data.donation_per_vote}
                                        </ButtonDefault>

                                        <p className="votingText">Cann't change voting price because votes already have been casted.</p>
                                    </>
                            }
                        </Col>
                    </Row>

                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    
                    <Row gutter={[0, 26]}>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0}></Col>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0}></Col>
                        <Col span={24}>
                            <TextArea
                                label="Share Your Story!"
                                name="share_your_story"
                                placeholder={tooltips.welcomeText.text}
                            />
                        </Col>
                        <Col span={24}>
                            <UploadImages
                                full={true}
                                name="Upload Images"
                                value={data.upload_images}
                                onChange={onChange}
                                tooltip={'Upload  a pic of your sonogram, baby bump, or a cute image of you and your family. People are more likely to play when they feel connected to YOU.'}
                            />
                        </Col>
                        <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
                        <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
                        <Col span={24}>
                            <Row gutter={[8, 0]} align="bottom">
                                <Col span={12}>
                                    <ButtonDefault
                                        primary={true}
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isLoading? <Spinner forBtn={true}/> : "Save"}
                                    </ButtonDefault>
                                </Col>
                                <Col span={12}>
                                    <ButtonDefault
                                        onClick={(e) => {
                                            navigate("/dashboard")
                                        }}
                                    >
                                        Don't Save
                                    </ButtonDefault>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
};

export default GameInfoStep;