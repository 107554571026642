import { combineReducers } from 'redux'

import authSlice from './auth/authSlice';
import userSlice from './user/userSlice';
import gameSlice from './game/gameSlice';

const reducers = combineReducers({
    auth: authSlice,
    user: userSlice,
    game: gameSlice
})

export default reducers;