import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const checkPhone = ({ phone }) => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i;

  if (regex.test(phone) === false) {
    toast.error("You must enter a valid phone number to continue");
    return false;
  }

  return true;
};
