
import './dashboard.scss';
import React, {useEffect, useState} from 'react';
import Layout from '../../../../../shared/components/layout';
import { Col, Row } from 'antd';
import GameDetails from "../../../shared/components/gameDetails";
import PlaysAndDays from "../../../shared/components/playsAndDays";
import DonationDetails from "../../../shared/components/donationDetails";
import ShareGame from '../../../shared/components/shareGame';
import ShareBtns from '../../../shared/components/shareBtns';
import ShareUrl from '../../../../../shared/components/shareUrl';
import Card from "../../../../../shared/components/card";
import { useSelector } from 'react-redux';
import { getSeletedGame, getShareModalVisibility, setSeletedGame, setShareModalVisibility } from '../../../../../shared/redux/game/gameSlice';
import { app } from '../../../../../shared/config';
import Api from '../../../../../shared/api/Api';
import { useDispatch } from "react-redux";
import Spinner from '../../../../../shared/components/spinner';
import { useNavigate } from 'react-router-dom';
import { getUser } from "../../../../../shared/redux/user/userSlice";
import Modal from '../../../../../shared/components/modal';
import {
	WhatsappShareButton,
	FacebookShareButton,
	FacebookMessengerShareButton,
	TwitterShareButton,

	WhatsappIcon,
	FacebookIcon,
	FacebookMessengerIcon,
	TwitterIcon,
} from "react-share";

const Dashboard = () => {
	const user = useSelector(getUser)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const game = useSelector(getSeletedGame)
	const [isLoading, setIsLoading] = useState(false)
	const [updateDeleted, setUpdateDeleted] = useState(false)
	const isModalVisible = useSelector(getShareModalVisibility)
	const [isVisible, setIsVisible] = useState(false)
	useEffect(() => {
		if (game?.contest?.id) {
			setIsLoading(true)
			return Api.get(`/contests/${game?.contest?.id}`, { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' } }).then(
				(res) => {
					if (res?.status == 200) {
						dispatch(setSeletedGame({ selectedGame: res?.data }))
					}
					setIsLoading(false)
				}
			).catch((err) => {
				if(err.response.status === 404){
					setIsLoading(false)
					navigate("/confirmation")
				}
			})
		}
	}, [updateDeleted])

	useEffect(() => {
		if(isModalVisible){
			setTimeout(
				() => setIsVisible(true),
				2000
			);
		}
		
	}, [isModalVisible])
	
	return (
		<>
			<Layout>
				<Card>
					{
						isLoading ?
							<Spinner />
							:
						<Row gutter={[20,20]}>
							<Col xs={24} sm={24} md={14} lg={15} xl={15}>
								<GameDetails game={game} user={user} setUpdateDeleted={() => setUpdateDeleted(!updateDeleted)}/>
							</Col>
							<Col xs={24} sm={24} md={10} lg={9} xl={9}>
								<Row gutter={[16, 30]}>
									<Col span={24}><PlaysAndDays game={game} /></Col>
									<Col span={24}>
										<ShareBtns 
											game={game}
											url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`}
										/>
									</Col>
									<Col span={24}>
										<DonationDetails 
											title="Total Raised" 
											tooltipText="Winning Prize" 
											game={game}
										/>
									</Col>
									<Col span={24}>
										<ShareUrl text="The more you share, the more money you’ll raise!" 
										url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`}
										/>
									</Col>
									<Col span={24}><ShareGame game={game} fromDashBoard={true}/></Col>
								</Row>
							</Col>
						</Row>
					}
				</Card>
			</Layout>
			<Modal visible={isVisible} handleClose={() => {dispatch(setShareModalVisibility({ showModal: false })); setIsVisible(false)}}>
					<div>
						<p className="title">Start sharing to start raising</p>
						<div className="socialIconsBox">
							<WhatsappShareButton url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} title={"Play my Gender Reveal Game! Guess if we are having a Boy or girl with a $20 play. One winner who guess right will win a prize! Play now"}>
								<WhatsappIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
							</WhatsappShareButton>
							<FacebookShareButton url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} hashtag={"#GenderReveal"} quote={"Play my Gender Reveal Game! Guess if we are having a Boy or girl with a $20 play. One winner who guess right will win a prize! Play now"}>
								<FacebookIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
							</FacebookShareButton>
							<FacebookMessengerShareButton appId="1111927622279359" url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} quote={"Play my Gender Reveal Game! Guess if we are having a Boy or girl with a $20 play. One winner who guess right will win a prize! Play now"}>
								<FacebookMessengerIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
							</FacebookMessengerShareButton>
							<TwitterShareButton url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} hashtag={"#GenderReveal"} title={"Play my Gender Reveal Game! Guess if we are having a Boy or girl with a $20 play. One winner who guess right will win a prize! Play now"}>
								<TwitterIcon size={"2.5rem"} round={true} />
							</TwitterShareButton>
						</div>
						
					</div>
			</Modal>
		</>
	)
}

export default Dashboard;