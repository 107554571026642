import React, {useState} from "react";
import PropTypes from 'prop-types';
import RadioField from '../../../../../components/RadioField/RadioField.js';
import ButtonDefault from '../../../../../components/ButtonDefault/ButtonDefault.js';
import {
    contactInformation,
    copyRightPolicy,
    disclaimers,
    limitationOnLiability,
    terms,
    userContent
} from "./termsAndConditions";
import classNames from 'classnames';


const ReviewAndSubmitStep = ({data={}, onBack = () => {}}) => {
    const [termsErrors, setTermsError] = useState("* We need your permission before we proceed. Please agree to our terms and conditions")
    const [gameTermsError, setGameTermsError] = useState(false);

    return (
    <>
      <h2>Terms and Conditions</h2>
      <div className="step-information">
        <div className="container-text">
            <p>{terms}</p>
            <p>{userContent}</p>
            <p>{disclaimers}</p>
            <p>{limitationOnLiability}</p>
            <p>{copyRightPolicy}</p>
            <p>{contactInformation}</p>
        </div>

        <RadioField
            id="terms"
            label={data.email_opt_in_text}
            value={gameTermsError}
            onClick={()=>{
                if(!!termsErrors){
                  setTermsError(null)
                }
                else{
                  setTermsError("* We need your permission before we proceed. Please agree to our terms and conditions")
                }
                setGameTermsError(!gameTermsError)
            }}
            defaultChecked={gameTermsError}
            checked={gameTermsError}
            showRed
        />
        <small className="termsError">{termsErrors}</small>
      </div>
      <div className="btns">
        <ButtonDefault secondary back onClick={onBack}>Back - to the Nitty Gritty</ButtonDefault>
        <ButtonDefault 
            primary 
            next 
            className={classNames({ 'primaryDisabled': !gameTermsError})} 
            type="submit"
            disabled={!gameTermsError}
            >Finish and Play!</ButtonDefault>
      </div>
    </>
  )
}
ReviewAndSubmitStep.propTypes = {
    data: PropTypes.object,
    onBack: PropTypes.func,
}
export default ReviewAndSubmitStep;