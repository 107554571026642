import './playsAndDays.scss';
import React from 'react';

const PlaysAndDays = ({game}) => {
  return (
    <div className="PlaysAndDays">
      <div className="box">
        <label>Plays</label>
        <strong>{parseInt(game?.summary?.boy_votes) + parseInt(game?.summary?.girl_votes)}</strong>
      </div>
      <div className="box">
        <label>Days</label>
        <strong>{game?.summary?.days_num < 0 ? 0 : game?.summary?.days_num}</strong>
      </div>
    </div>
  )
}

export default PlaysAndDays;