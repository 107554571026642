import React, { useState, useEffect } from 'react';
import Layout from '../../../../../shared/components/layout';
import { Col, Row, Typography } from 'antd';
import Card from "../../../../../shared/components/card";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import GenderCard from "../../components/genderCard";
import CashOutCard from "../../components/cashOutCard";
import VotingDetailsCard from "../../components/votingDetails";
import ShareGame from "../../../shared/components/shareGame";
import Api from '../../../../../shared/api/Api';
import './endGame.scss';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../shared/redux/user/userSlice';
import { getSeletedGame, setSeletedGame } from '../../../../../shared/redux/game/gameSlice';
import { getAccessToken } from '../../../../../shared/redux/auth/authSlice';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useWindowSize from "../../../../../shared/hooks/use-window-size";

const { Title, Paragraph } = Typography;

const EndGame = () => {
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const game = useSelector(getSeletedGame)
	const token = useSelector(getAccessToken)
	const [isLoading, setIsLoading] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const { width } = useWindowSize()
	const launchAt = new Date('2022-11-01')

	const handleChooseGender = async (gender) => {
		setIsModalVisible(false)
		setIsLoading(true)
		const response = await getWinner(gender)

		if (response.success) {
			Api.get(`/contests/${response?.data?.contest_id}`, { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type' } }).then(
				(res) => {
					if (res?.status == 200) {
						dispatch(setSeletedGame({ selectedGame: res?.data }))
					}
				}
			)
		}
		else {
			toast.error("There is an error. Please try later.", { position: "top-center" })
		}
		setIsLoading(false)
	}

	const endGame = () => {
		return Api.patch(`/contests/end/${game?.contest?.id}`,
			{
				headers: {
					'X-User-Token': `${token}`,
					'X-User-Email': `${user?.email}`
				}
			}).then((res) => {
				return {
					success: true,
					data: res.data
				}
			}).catch((err) => {
				return {
					success: false,
					message: err.response.data.message
				}
			})
	}


	const getWinner = (gender) => {
		return Api.post(`/contests/closeout`,
			{
				id: game?.contest?.id,
				baby_gender: gender
			},
			{
				headers: {
					'X-User-Token': `${token}`,
					'X-User-Email': `${user?.email}`
				}
			}).then((res) => {
				return {
					success: true,
					data: res.data
				}
			}).catch((err) => {
				return {
					success: false,
					message: err.response.data.message
				}
			})
	}

	useEffect(() => {
		async function stopVoting() {
			const voting = await endGame()
			if (voting.success) {
				toast.success("Contest ended successfully, Now, no more votes can be cast.", { position: "top-center" })
			}
		}

		if (!game?.contest?.voting_ended) {
			stopVoting()
		}

	}, [])

	return (
		<>
			<Layout>
				<Card>
					<Row>
						<Col span={24} className="endGameContainer">
							<Row justify="space-between" align="middle">
								<Col><Title className="heading">End Game & Cash Out</Title></Col>
							</Row>
							<Row justify="space-between" align="middle">
								<Col><Paragraph className="subHeading">Select the gender of the child to complete the game{width <= 450 ? " " : <br />}and determine the winner</Paragraph></Col>
								{/* <Col><ButtonDefault className="deleteBtn">Delete Game</ButtonDefault></Col> */}
							</Row>

							<Row gutter={[24, 8]} >
								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<GenderCard
										game={game}
										handleChooseGender={handleChooseGender}
										isVisible={isModalVisible}
										setIsVisible={setIsModalVisible}
									/>
								</Col>
								<Col xs={24} sm={24} md={16} lg={16} xl={16}>
									<CashOutCard
										game={game}
										isLoading={isLoading}
									/>
								</Col>
							</Row>

							<Row gutter={[24, 8]}>
								{
									new Date(game?.contest?.created_at) < launchAt ?
									game?.vote_counts?.length != 0 &&
									<Col xs={24} sm={24} md={16} lg={16} xl={16}>
										<VotingDetailsCard game={game} />
									</Col>
									:
									<Col xs={24} sm={24} md={16} lg={16} xl={16}>
										<VotingDetailsCard game={game} />
									</Col>
								}
								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<Card className="shareGame">
										<ShareGame
											game={game}
											title="Other"
											fromEndGame={true}
										/>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>

				</Card>
			</Layout>
		</>
	)
};

export default EndGame;