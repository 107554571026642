import React from "react";
import BackgroundHearts from '../../../../components/BackgroundHearts.js';
import ButtonDefault from '../../../../components/ButtonDefault/ButtonDefault.js';
import './forgotPassword.scss';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Api from "../../../../shared/api/Api.js";
import { getQueryParams } from "../../../../shared/utils/helper";
import Spinner from "../../../../shared/components/spinner/index.jsx";
import { Form, Input } from 'antd';
import {LockOutlined} from '@ant-design/icons';

const CreateNewPassword = () => {
	const resetPasswordToken = getQueryParams().get("token")
	const [passwordSetSuccessfully, setPasswordSetSuccessfully] = useState(false)
	const navigate = useNavigate();
	const [createNewPasswordError, setCreateNewPasswordError] = useState(false);
	const [createNewPasswordTokenError, setCreateNewPasswordTokenError] = useState(false);
	const [errors, setErrors] = useState(null)
	const [isLoading, setIsLoading] = useState(false)


	const createNewPassword = (values) => {
		if(values?.password?.trim() === ""){
			return setErrors({ password: "Please also add at least one character other than space" })
		}

		setIsLoading(true)
		return Api.post(`/password/reset`, {
			reset_password_token: resetPasswordToken,
			password: values.password,
		},
			{
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers': 'Content-Type'
				}
			}).then((res) => {
				setIsLoading(false)
				if (res.status === 200) {
					setPasswordSetSuccessfully(true)
				} else {
					return { success: false, reason: res.message }
				}
			}).catch((err) => {
				setIsLoading(false)
				if (err.response.status === 401) {
					setCreateNewPasswordError(true);
				}
				else if (err.response.status === 400) {
					setCreateNewPasswordTokenError(true);
				}
				return { success: false, reason: err.response.data.message }
			})
	};

	const handleError = () => {
        if(errors){
            setErrors(null)
        }
    }

	return (
		<BackgroundHearts>
			<div className="create-new-password-screen">
				<div className="frame">
					<div className="forgot-password">
						{
							passwordSetSuccessfully === false ?
								createNewPasswordTokenError ?
									<>
										<h1>Your link has been used<br /> or expired !</h1>
										<p className="subtext">Go to the forgot password page and get a new email</p>
										<ButtonDefault onClick={() => navigate("/forgot-password")} primary={true} className='go-to-login-page'>Go To Forgot Password Page</ButtonDefault>
									</>
									:
									<>
										<h1>Create new password</h1>
										<p className="subtext">Password must contain at least <br />8 symbols</p>
										<Form
											name="createNewPassword"
											layout="vertical"
											style={{ maxWidth: '353px' }}
											onFinish={createNewPassword}
										>
											<Form.Item
												name="password"
												className="formItem"
												rules={[
													{
														required: true,
														min: 8,
														message: "Less than 8 characters entered"
													},
												]}
											>
												<Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter your new password" onChange={handleError}/>
											</Form.Item>
											{
												errors?.password && <p className="error-msg">{errors?.password}</p>
											}
											<Form.Item
												name="repeatPassword"
												className="formItem"
												rules={[
													{
														required: true,
														message: 'Please make sure your passwords match.',
													},
													({ getFieldValue }) => ({
														validator(_, value) {
															if (!value || getFieldValue('password') === value) {
																return Promise.resolve();
															}
															return Promise.reject(new Error('The passwords do not match'));
														},
													}),
												]}
											>
												<Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter password again" />
											</Form.Item>
											{createNewPasswordError && <div className="error-msg">
												* The email address doesn&apos;t exists
											</div>}
											<Form.Item>
												<ButtonDefault type="submit" primary={true} className='renew-password'>{isLoading ? <Spinner forBtn /> : "Renew Password"}</ButtonDefault>
											</Form.Item>
										</Form>
									</>
								: <>
									<h1>Your password has been<br />successfully changed !</h1>
									<p className="subtext">Go to the login page and log in with<br />the new password</p>
									<ButtonDefault onClick={() => navigate("/")} primary={true} className='go-to-login-page'>Go TO Login Page</ButtonDefault>
								</>
						}
					</div>
				</div>
			</div>
		</BackgroundHearts>)
}

export default CreateNewPassword