import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getAccessToken } from '../redux/auth/authSlice';

const ProtectedRoute = ({children, to}) => {
    const accessToken = useSelector(getAccessToken)
    return (
      <>
        {
          accessToken
              ? children
              : to === "signup" ? <Navigate to="/" /> : <Navigate to="/login" />
        }
      </>
    );
  };
  
  export default ProtectedRoute;