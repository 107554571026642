import Separator from "../../../../../components/Separator";
import StripeContainer from "../../../../../shared/components/stripe/stripeContainer";
import Paypal from "../../../../../shared/components/paypal";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypal } from "../../../../../shared/config";
import Api from "../../../../../shared/api/Api";
import { setVotes } from "../../../../../shared/redux/game/gameSlice";
import { useDispatch } from "react-redux";

const paypalScriptOptions = {"client-id": paypal.PAYPAL_KEY,currency: "USD"};

const FormPayment = ({votes, contestId, total}) => {
const dispatch = useDispatch()


  const handlePayment = (data) => {
    const payload = {
      boy_or_girl: votes?.for === 'boy'? 0 : 1,
      num_votes: votes?.noOfVotes,
      contest_id: contestId,
      ...data
    }
    return  Api.post(`/checkout`, payload).then((res) => {
        dispatch(setVotes({ votes:  {for: null, noOfVotes: null} }))
        return {success: true, data: res.data}
    }).catch((err) => {
      return {
        success: false,
        message: err.response.data.message
      }
    })
  }

  return (
    <div className="FormPayment box">
      <h2>Payment</h2>
      <PayPalScriptProvider options={paypalScriptOptions}>
        <Paypal handlePayment={handlePayment} value={total} vote={votes}/>
      </PayPalScriptProvider>
      <Separator>or</Separator>
        <StripeContainer handlePayment={handlePayment}/>
        <p className="cards">
          Cards Accepted 
          <img src="../assets/mastercard.png" alt="Mastercard" width="35px" height="24px" />
          <img src="../assets/visa.png" alt="Visa" width="35px" height="24px" />
          <img src="../assets/discover.png" alt="Discover" width="35px" height="24px" />
        </p>
    </div>
  );
};

export default FormPayment;