export const tooltips = {
    gameName: {
        title: "Game Name",
        text: "It's time to name your game! Be creative and have some fun. You can name this game whatever you want!"
    },
    contestPrize: {
        title: "Contest Prize",
        text: "Now, we pick the prize! This can be a bag of candy, a gift card to a nice restaurant or whatever you like!"
    },
    contestEndDate: {
        title: "Contest End Date",
        text: "Select which date you'll end the game! You can change this date whenever you'd like."
    },
    costPerVote: {
        title: "Cost Per Vote",
        text: "How much do you want to charge per play? Please note: You can't change this amount after the first person has played."
    },
    welcomeText: {
        title: "Share Your Story!",
        text: "Use this space to share a brief welcome message with your players. Tell them more about your growing baby and how you will use the funds raised through this game!"
    },
    uploadImage: {
        title: "Upload Images Here",
        text: "Upload  a pic of your sonogram, baby bump, or a cute image of you and your family. People are more likely to play when they feel connected to YOU."
    }
}

export const advices = [
    "Some fun prizes we’ve seen moms offer are a gift card, special present, or gift basket.",
    "Did you know that anyone, anywhere in the world with your game link can play?",
    "Rest assured that we use high-security software to process pay payments and payouts!",
    "All of the players have a chance to win. One player is randomly selected the game ends and gender is announced.",
] 