import GameHost from '../../../host/page/editGame';
import SidebarCompleted from '../../../../../components/SidebarCompleted/SidebarCompleted';

import './completed.scss';

const Completed = () => {
  return (
    <div className="Completed container">
      <div className="frame">
        <GameHost />
        <SidebarCompleted />
      </div>
    </div>
  );
};

export default Completed;