import "./shareGame.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import Modal from "../../../../../shared/components/modal";
import { app } from "../../../../../shared/config";
import { toast } from "react-toastify";
import Spinner from "../../../../../shared/components/spinner";
import { useSelector } from 'react-redux';
import { getAccessToken } from "../../../../../shared/redux/auth/authSlice";
import { getUser } from "../../../../../shared/redux/user/userSlice";
import Api from "../../../../../shared/api/Api";
import classNames from "classnames";
const ShareGame = ({ game, title = "Spread the Love!", fromEndGame = false, fromDashBoard = false }) => {
	const token = useSelector(getAccessToken)
	const user = useSelector(getUser)
	const navigate = useNavigate();
	const [visible, setVisibile] = useState(false);
	const [action, setAction] = useState(0);
	const [isLoading, setLoading] = useState(false)
	const handleAction = (action) => {
		if (!!game?.contest?.voting_ended && action === 2) {
			return handleEndGame()
		}
		setAction(action);
		setVisibile(!visible);
	};

	const handleDelete = async () => {
		setLoading(true)
		await deleteGame()
		setLoading(false)
		setVisibile(!visible);
		window.location.replace("https://www.genderrevealgame.villie.co/")
	};

	const handleEndGame = () => {
		navigate("/dashboard/end-game");
		setVisibile(false);
	};

	const handleCopyUrl = (url) => {
		if (navigator.clipboard.writeText(url)) {
			return toast.success("Url copied successfully");
		}
		return toast.error("Could not copy URL");
	};

	const deleteGame = () => {
		return Api.delete(`/contests/${game?.contest?.id}`,
			{
				headers: {
					'X-User-Token': `${token}`,
					'X-User-Email': `${user?.email}`
				}
			}).then((res) => {
				return {
					success: true,
					data: res.data
				}
			}).catch((err) => {
				return {
					success: false,
					message: err.response.data.message
				}
			})
	}

	const btnsDisabled = () => {
		return !!(game?.contest?.voting_ended)
	}

	return (
		<div className="buttons-box">
			{
				fromEndGame ?
					<p className="endGameTitle">Other</p>
					:
					<p className="title">
						<img alt="icon" src="../assets/heart.svg" /> {title}
					</p>
			}


			<ButtonDefault primary onClick={() => { handleAction(3) }}>Share this Game!</ButtonDefault>
			{
				fromDashBoard &&
				<>
					{!btnsDisabled() && <ButtonDefault secondary onClick={() => { navigate("/dashboard/edit-game") }}>Edit Game</ButtonDefault>}
					<ButtonDefault
						secondary
						onClick={() => { navigate("/dashboard/add-update") }}
						className={classNames({ 'width100': btnsDisabled() })}
					>
						Add Baby Update
					</ButtonDefault>
					<ButtonDefault
						secondary
						onClick={() => navigate(`/play-game?gameId=${game?.contest?.id}`)}
						className={classNames({ 'width100': btnsDisabled() })}
					>
						Guest View
					</ButtonDefault>
					{!btnsDisabled() && <ButtonDefault secondary onClick={() => { handleAction(1) }}>Delete Game</ButtonDefault>}
					<ButtonDefault primary onClick={() => { handleAction(2) }}>End Game and Start Cash Out</ButtonDefault>
				</>
			}
			{
				fromEndGame &&
				<>
					<ButtonDefault onClick={() => navigate(`/play-game?gameId=${game?.contest?.id}`)} className="bgGray">Guest View</ButtonDefault>
				</>
			}


			<Modal visible={visible} handleClose={() => setVisibile(false)}>
				{action === 1 && (
					<div>
						<p className="title">Delete Game?</p>
						<p className="description">
							Are you sure you want to delete your game? Deleted games cannot recover and all money will be returned to the players within 5-7 business days.
						</p>
						<ButtonDefault
							onClick={handleDelete}
							danger
						>
							{isLoading ? <Spinner forBtn={true} /> : "Yes, Delete This Game"}
						</ButtonDefault>
					</div>
				)}
				{action === 2 && (
					<div>
						<p className="title">End Game?</p>
						<p className="description">
							Yay! It’s time to reveal the baby’s gender! Are you ready to share your good news with the world?
						</p>
						<ButtonDefault danger onClick={handleEndGame}>Yes, I Want To End Early</ButtonDefault>
					</div>
				)}
				{action === 3 && (
					<div>
						<p className="title">{`${game?.contest?.name}!`}</p>
						<ButtonDefault
							primary
							onClick={() => handleCopyUrl(`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`)}
						>
							<img src="../assets/login.png" alt="" />
							Copy URL To Share
						</ButtonDefault>
					</div>
				)}
			</Modal>
		</div>
	);
};

export default ShareGame;