import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const checkEmail = ({ email }) => {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  if (regex.test(email) === false) {
    toast.error("You must enter a valid email to continue");
    return false;
  }

  return true;
};
