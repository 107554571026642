import './updateGame.scss';
import React, { useState } from "react";
import TextAreaField from "../../../../../components/TextAreaField/TextAreaField";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import Layout from "../../../../../shared/components/layout";
import Card from "../../../../../shared/components/card";
import { Col, Row } from 'antd';
import {batch, useSelector} from 'react-redux';
import { getSeletedGame } from '../../../../../shared/redux/game/gameSlice';
import Api from '../../../../../shared/api/Api';
import { blobToBase64 } from "../../../../../shared/utils/helper";
import {getUser, resetUserState} from "../../../../../shared/redux/user/userSlice"
import {getAccessToken, resetAuthState} from "../../../../../shared/redux/auth/authSlice"
import { stopEventPropagation } from '../../../../../shared/utils/helper';
import Spinner from '../../../../../shared/components/spinner';
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
const UpdateGame = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const token = useSelector(getAccessToken)
	const game = useSelector(getSeletedGame)
	const initState = {text: '',upload_images: []}
	const [dataForm, setDataForm] = useState(initState);
	const [isLoading, setIsLoading] = useState(false);

	const changeForm = (e) => {
		setDataForm({
		...dataForm,
		[e.id]: e.value
		})
	}

	const convertBlobToBase64 = async (images) => {
		if(!images){
			return []
		}
		let i = -1
		return await Promise.all(images?.map(async(image) => {
		  const img = await blobToBase64(image?.blob)
			i += 1
			return {id: i, delete_flag:null, description: "", image: img.replace("application/zip", "image/jpeg")}
		}))
	  }

	const submitForm = async (e) => {
		stopEventPropagation(e)
		let payload = {
			"contest_id": game?.contest?.id,
			"message": dataForm.text,
			"contest_update_photos": await convertBlobToBase64(dataForm?.upload_images),
			"contest": {user: user?.id},
		}
		setIsLoading(true);
		const response = await updateGame(payload)

		if(response.success ){
		  setIsLoading(false);
		  navigate('/dashboard');
		}
	}

	const updateGame = (payload) => {
		return  Api.post(`/contest_updates`, payload,
			{
			  headers: {
				'X-User-Token': `${token}`,
				'X-User-Email': `${user?.email}`
			  }
			}).then((res) => {
			  setIsLoading(false);
			  return {
			success: true,
			data: res.data
		  }
		}).catch((err) => {
			if(err.response.status === 401){
				batch(() => {
					dispatch(resetAuthState())
					dispatch(resetUserState())
				})
				Api.delete(`/sessions`,
					{
						data: {
							"email": user.email,
						}
					},
					{
						headers: {
							'Access-Control-Allow-Origin': '*',
							'Access-Control-Allow-Headers': 'Content-Type'
						}
					}).then((res) => {
					if (res.data.email) {
						sessionStorage.clear()
						localStorage.clear()
						navigate("/");
					} else {
						return { success: false, reason: res.message }
					}
				}).catch((err) => {
					return { success: false, reason: err.response.data.message }
				})
				navigate("/");
			}
		  return {
			success: false,
			message: err.response.data.message
		  }
		})
	}
  return (
    <Layout>
      	<Card className="updateCard">
      		<Row gutter={[20,20]}>
				<Col xs={24} sm={24} md={15} lg={15} xl={15}>
					<div className="AddUpdate">
						<h1>Add Update</h1>
						<form>
						<TextAreaField id="text" name="Keep your players updated on your bundle of joy’s progress." value={dataForm.text} onChange={changeForm} placeholder="Enter welcome text to welcome players and tell them about yout story...." />
						<ButtonDefault primary 
							onClick={submitForm}
						>
							{isLoading? <Spinner forBtn={true}/> : "Baby Update"}
						</ButtonDefault>
						</form>
					</div>
				</Col>
			</Row>
      	</Card>
    </Layout>
  )
}

export default UpdateGame;