import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./Navbar.scss";
import ButtonLink from "../ButtonLink/ButtonLink";
import Api from "../../shared/api/Api";
import { batch, useDispatch, useSelector } from 'react-redux';
import { getAccessToken, resetAuthState } from "../../shared/redux/auth/authSlice";
import { getUser, resetUserState } from "../../shared/redux/user/userSlice";

const Navbar = () => {
	const accessToken = useSelector(getAccessToken)
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();
	const handleClickMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const handleLogout = () => {
		batch(() => {
			dispatch(resetAuthState())
			dispatch(resetUserState())
		})
		Api.delete(`/sessions`,
			{
				data: {
					"email": user.email,
				}
			},
			{
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers': 'Content-Type'
				}
			}).then((res) => {
				if (res.data.email) {
					sessionStorage.clear()
					localStorage.clear()
					navigate("/");
				} else {
					return { success: false, reason: res.message }
				}
			}).catch((err) => {
				return { success: false, reason: err.response.data.message }
			})
		navigate("/");
	};

	return (
		<nav id="navbar" className={`navbar ${menuOpen ? "active" : ""}`}>
			<div className="background" onClick={handleClickMenu} />
			<div className="container">
				<a href="https://www.genderrevealgame.villie.co/"  target="_blank" rel="noopener noreferrer">
					<img className="logo" src={`../assets/logo.png`} alt="logo" />
				</a>

				{/* {!menuOpen && <img className="line-nav" src={`../assets/line-navbar.png`} alt="line-navbar" />} */}

				<div className="btn-menu">
					<img
						id="btn-menu"
						onClick={handleClickMenu}
						src={`../assets/burger.svg`}
						alt="burger"
					/>
				</div>

				<div className="navigation">
					<div className="item-list">
						<ul className="menu">
							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active" : "")}
									to="/live-games"
								>
									Live Games
								</NavLink>
							</li>
							{
								!!accessToken &&
								<li>
									<img src={`../assets/heart.svg`} alt="icon" />
									<NavLink
										className={({ isActive }) => (isActive ? "active" : "")}
										to="/confirmation"
										onClick={handleClickMenu}
									>
										Dashboard
									</NavLink>
								</li>
							}
							<li>
								<a
									className={({ isActive }) => (isActive ? "active" : "")}
									href="https://www.genderrevealgame.villie.co/blogs"
									onClick={handleClickMenu}
									target='_blank'
									rel="noopener noreferrer"
								>
									Blog
								</a>
							</li>
							<li>
								<a
									className={({ isActive }) => (isActive ? "active" : "")}
									href="https://www.genderrevealgame.villie.co/faq"
									onClick={handleClickMenu}
									target='_blank'
									rel="noopener noreferrer"
								>
									FAQ&apos;s
								</a>
							</li>
						</ul>
						{
							!!accessToken &&
								<ul className="options">
									<li>
										<img src={`../assets/game.svg`} alt="icon" />
										<NavLink
											className={({ isActive }) => (isActive ? "active" : "")}
											to="/confirmation?navbar=true"
											onClick={handleClickMenu}
										>
											My Games
										</NavLink>
									</li>
									{/* <li>
										<img src={`../assets/edit.svg`} alt="icon" />
										<NavLink
											className={({ isActive }) => (isActive ? "active" : "")}
											to="/dashboard/edit-game"
											onClick={handleClickMenu}
										>
											Edit Account
										</NavLink>
									</li> */}
									<li>
										<img src={`../assets/logout.svg`} alt="icon" />
										<NavLink
											className={({ isActive }) => (isActive ? "active" : "")}
											to="/"
											onClick={handleLogout}
										>
											Logout
										</NavLink>
									</li>
								</ul>
						}
					</div>
					{
						!!accessToken ?
						<ButtonLink text="Create A Game" link="/create-game" />
						:
						<ButtonLink text="Login" link="/login" />
					}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
