import React from "react";
import { Row, Col, Typography } from 'antd';
import Card from "../../../../../shared/components/card";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import PieChart from "../../../../../shared/components/pieChart";
import './cashOutCard.scss';
import classNames from 'classnames';
import { calculateVoterPercentage } from "../../../../../shared/utils/helper";
import Modal from "../../../../../shared/components/modal";
import { useState, useEffect } from "react";
import { getGender } from "../../../../../shared/utils/helper";
const { Paragraph } = Typography;

const CashOutCard = ({ game, isLoading }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [votesCount, setVotesCount] = useState(null)
    const createdAt = new Date(game?.contest?.created_at)
	const launchAt = new Date('2022-11-01')

    const cashOut = () => {
        if(game?.contest?.baby_gender === 0 || game?.contest?.baby_gender === 1){
            window.open('https://genderrevealgame.villie.co/cash-out', '_blank');
        }
        else{
            setIsVisible(true)
        }
    }

    useEffect(() => {
		if(createdAt < launchAt){
			if(game?.summary){
				setVotesCount({boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes})
			}
		}
		else{
			if(game?.summary){
				const temp = getGender(game?.summary?.contest_name) == 0 ? {boy: game?.summary?.boy_votes + 1, girl: game?.summary?.girl_votes} : {boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes + 1}
				setVotesCount(temp)
			}
		}
	}, [game])

    return (
        <>
            <Card className="cashOutCard">
                <Row>
                    <Col xs={24} sm={24} md={11} lg={12} xl={12} className="left">
                        <Paragraph className="title">Cash Out And End Game</Paragraph>
                        <Card className={game?.winner?.voter_first_name ? "winnerAnnounced": "spinWheelCard"}>
                            {
                                game?.winner?.voter_first_name ?
                                <>
                                    <Paragraph className="title">winner</Paragraph>
                                    <Paragraph className="winner">{game?.winner?.voter_first_name} {(game?.winner?.voter_last_name)?.substring(0,1)}.</Paragraph>
                                </>
                                
                                :
                                <>
                                    <img className="spinnerGif" src={isLoading ? "../assets/spinner.gif" : "../assets/trophy.svg"} alt="bear" />
                                    <br />
                                    <Paragraph className="text">{isLoading ? "Spin the Wheel..." : "The winner will appear here"}</Paragraph>
                                </>
                            }
                            
                        </Card>
                         <ButtonDefault
                                primary disabled={!game}
                                className={classNames("btn", { 'primaryDisabled': !game })}
                                onClick={cashOut}
                            >
                                Cash Out & End Game
                        </ButtonDefault>
                        <ButtonDefault disabled={!game?.winner?.voter_first_name} className={classNames("btn", { 'defaultDisabled': !game?.winner?.voter_first_name })}>Send Result To Winner</ButtonDefault>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={12} xl={12} className="right">
                        <Paragraph className="title">Static</Paragraph>
                        <Row className="row">
                            <Col span={24}>
                                <Paragraph className="head">Total Raised</Paragraph>
                                <Paragraph className="paragraph">$ {createdAt < launchAt ? game?.summary?.money_raised : game?.summary?.money_raised + game?.contest?.min_cost_per_vote}</Paragraph>
                            </Col>
                        </Row>

                        <Row className="row">
                            <Col span={12}>
                                <Paragraph className="head">Winner Prize</Paragraph>
                                <Paragraph className="paragraph">{game?.contest?.prize_name}</Paragraph>
                            </Col>
                            <Col span={12}>
                                <Paragraph className="head">days left</Paragraph>
                                <Paragraph className="paragraph">{game?.summary?.days_num > 0 ? game?.summary?.days_num : 0}</Paragraph>
                            </Col>
                        </Row>

                        <Row className="row" align="bottom">
                            <Col span={8}>
                                <Paragraph className="head">Plays</Paragraph>
                                <Paragraph className="paragraph">{parseInt(votesCount?.boy) + parseInt(votesCount?.girl)}</Paragraph>
                                <Paragraph className="text">{votesCount?.girl} Girls</Paragraph>
                                <Paragraph className="text">{votesCount?.boy} Boys</Paragraph>
                            </Col>
                            {
                                votesCount &&
                                <Col span={12} className="chart">
                                    <PieChart data={[
                                        { name: "Girl", value: calculateVoterPercentage(votesCount?.boy, votesCount.girl, "girl"), color: "#D9ABFE" },
                                        { name: "Boy", value: calculateVoterPercentage(votesCount?.boy, votesCount.girl, "boy"), color: "#5263FF" },
                                    ]}/>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>

            <Modal visible={isVisible} handleClose={() => setIsVisible(false)}>
					<div>
                        <p className="title">Please select gender before cashout.</p>
					</div>
			</Modal>
        </>
    )
}

export default CashOutCard;