import React, {useState} from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from 'classnames';
import "./UploadImages.scss";
import TooltipInput from "../TooltipInput/TooltipInput";
import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const UploadImages = ({
  name = '',
  value = [],
  limitSize = 20971521,
  maxSize =  1048576 * 20,
  onChange,
  tooltip,
  full = false
}) => {
  const id = name.replace(/\s/g, '_').toLowerCase();
  const [imageSizeError, setImageSizeError] = useState(null)
  //Show thumbnail images and postback to parent component
  const createThumbnail = (files, i, thumbnailId) => {
    const binaryData = [];
    binaryData.push(files[i]);
    onChange({
      id,
      value: [
        ...value,
        {
          id: thumbnailId,
          src: URL.createObjectURL(
            new Blob(binaryData, { type: "application/zip" })
          ),
          formData: files[i].selectedFile,
          blob: new Blob(binaryData, { type: "application/zip" })
        }
      ]
    });
  };

  //Handler for the button that removes the thumbnail image
  const deleteThumbnail = (thumbID) => {
    const imagesUpdated = value.filter((image) => image.id !== thumbID);
    onChange({id, value: imagesUpdated });
  };

  //User-friendly byte to size converter
  const bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / Math.pow(1024, i), 2)}${sizes[i]}`;
  };

  //Controller for handling images loaded in the input file
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        if(files[i].size > maxSize){
          setImageSizeError("Oh no, this image is too big! Please try another image.")
          return e.target.value = "";
        }
        if(value.length === 3){
          alert("Only three images are allowed.");
          return e.target.value = "";
        }
        if (files[i]?.size <= limitSize) {
          let thumbnailId =
            Math.floor(Math.random() * 30000) + "_" + Date.now();
          createThumbnail(files, i, thumbnailId);
        } else {
          toast.error(
            `(${
              files[i].name
            }) I couldn't upload it because it weighs more than ${bytesToSize(
              limitSize
            )}`,
            {}
          );
        }
      }

      e.target.value = "";
    }
  };

  return (
    <div className={classNames("upload-images", {full: full})}>
      <p>
        {name !== "" && <span className="title">{name}</span>}
        <span>( Max {bytesToSize(limitSize)} Per Image)</span>
        { tooltip && <Tooltip title={tooltip}>
          <InfoCircleFilled style={{color: "#463649", cursor: "help", marginLeft: "3px"}}/>
        </Tooltip>}
      </p>

      <div className="image-container">
        <label className="image-btn">
          <img id="btn-menu" src={`../assets/plus.svg`} alt="burger" />
          <input
            name={`${id}[]`}
            style={{ display: "none" }}
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/gif, image/jpeg"
            multiple
            onClick={() => {
              if(!!imageSizeError){
                setImageSizeError(null)
              }
            }}
          />
        </label>

        {value.map(({ src, id }) => {
          return (
            <>
            <div className="preview-img" key={'preview-' + id} id={id}>
              <span
                className="btn-delete"
                onClick={() => {
                  deleteThumbnail(id);
                }}
              >
                <img src="../assets/delete.svg" alt="x" />
              </span>
              <img src={src} alt="thumb" />
            </div>
            </>
          );
        })}
      </div>
      <p className="imageSizeError">{imageSizeError}</p>
    </div>
  );
};

UploadImages.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  limitSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  full: PropTypes.bool,
};

export default UploadImages;