import React, { useEffect, useState } from 'react';
import StepsBar from '../../../../../components/StepsBar/StepsBar.js';
import YourInformationStep from "./YourInformationStep";
import ReviewAndSubmitStep from './ReviewAndSubmitStep.js';
import './CreateGameScrenn.scss';
import GameInformationStep from "./GameInformationStep";
import Api from '../../../../../shared/api/Api.js';
import { useNavigate } from "react-router-dom";
import { isAuth } from '../../../../../shared/api/Auth';
import { blobToBase64 } from "../../../../../shared/utils/helper";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUser } from "../../../../../shared/redux/user/userSlice"
import { getAccessToken } from "../../../../../shared/redux/auth/authSlice"
import { toast } from 'react-toastify';
import Spinner from '../../../../../shared/components/spinner/index.jsx';
import { stopEventPropagation } from "../../../../../shared/utils/helper";
import { Form } from 'antd';
import moment from "moment";

const CreateGame = () => {
	const dispatch = useDispatch()
	const [form] = Form.useForm();
	const user = useSelector(getUser)
	const token = useSelector(getAccessToken)
	const [step, setStep] = useState(1);
	const [dataForm, setDataForm] = useState({});
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [donationMoney, setDonationMoney] = useState(null)

	useEffect(async () => {
		!isAuth && navigate('/');
		setDataForm({ "email_opt_in_text": await getEmailOptInText({ version: 'game_creator' }) })
	}, [isAuth, navigate])
	const changeForm = (e) => {
		if (e.id == "country") {
			delete dataForm["state_or_province"]
		}
		if (e.id == 'share_your_story!') {
			setDataForm({
				...dataForm,
				'share_your_story': e.value
			})
		}
		else {
			setDataForm({
				...dataForm,
				[e.id]: e.value
			})
		}
	}

	const convertBlobToBase64 = async (images) => {
		if (!images) {
			return []
		}
		let i = -1
		return await Promise.all(images?.map(async (image) => {
			const img = await blobToBase64(image?.blob)
			i += 1
			return { id: i, delete_flag: null, description: "", image: img.replace("application/zip", "image/jpeg") }
		}))
	}

	const submitForm = async () => {
		let payload = {
			"user_id": user?.id,
			"name": dataForm.game_name,
			"description": dataForm.share_your_story,
      		"phone": dataForm.phone_number,
			"parent_1_first_name": dataForm.parent_one_first_name,
			"parent_1_last_name": dataForm.parent_one_last_name,
			"parent_2_first_name": dataForm.parent_two_first_name,
			"parent_2_last_name": dataForm.parent_two_last_name,
			"prize_name": dataForm.game_prize,
			"min_cost_per_vote": !dataForm.donation_per_vote ? donationMoney?.value : dataForm.donation_per_vote,
			"contest_photos": await convertBlobToBase64(dataForm?.upload_images),
			"finish_date": moment(dataForm.contest_end_date).format('YYYY-MM-DD'),
			"city": dataForm.city,
			"state": dataForm.state_or_province,
			"country": dataForm.country,
			"email_opt_in_text": dataForm.email_opt_in_text ? dataForm.email_opt_in_text : '',
		}
		setIsLoading(true);
		try {
			const game = await createGame(payload)

			if (game.success) {
				await getUserFromApi()
				setIsLoading(false);
				toast.success("Game created successfully", { position: "top-center" })

				setTimeout(
					() => navigate("/confirmation"),
					1500
				);
			}
			else {
				setIsLoading(false);
				toast.error("Unable to create game. Try again later", { position: "top-center" })
			}
		} catch (error) {
			setIsLoading(false);
			toast.error("Unable to create game. Try again later", { position: "top-center" })
		}

	}
	const getEmailOptInText = (payload) => {
		return Api.get(`/email_permission_text/${payload.version}`).then(
			(res) => {
				if (res.data) {
					return res.data.text
				}
			}
		)
	}
	const createGame = (payload) => {
		return Api.post(`/contests`, payload,
			{
				headers: {
					'X-User-Token': `${token}`,
					'X-User-Email': `${user?.email}`
				}
			}).then((res) => {
				setIsLoading(false);
				return {
					success: true,
					data: res.data
				}
			}).catch((err) => {
				return {
					success: false,
					message: err.response.data.message
				}
			})
	}

	const onFinish = (values) => {
		if (step === 3) {
			submitForm()
		}
		setStep(step + 1)
		setDataForm({ ...dataForm, ...values })
	};

	const getUserFromApi = () => {
		return Api.get(`/registrations?id=${user?.id}`).then(
			(res) => {
		dispatch(setUser({ user: {...user, phone: res?.data?.phone} }))
			}).catch((err) => {
				
		});
	}

	return (
		<main className="gray">
			<div style={{ maxWidth: '1440px', margin: 'auto' }}>
				<div className="create-game-screen">
					<h1>Create A Game</h1>
					{/*  onChange={ setStep } */}
					<StepsBar currentStep={step}/>
					<Form
						form={form}
						name="createGame"
						layout="vertical"
						onFinish={onFinish}
						autoComplete="off"
						className="frame"
					>
						{step === 1 && <YourInformationStep form={form}/>}
						{step === 2 &&
							<GameInformationStep
								data={dataForm}
								onChange={changeForm}
								onBack={(e) => { stopEventPropagation(e); setStep(1) }}
								donationMoney={donationMoney}
								setDonationMoney={setDonationMoney}
								form={form}
							/>
						}
						{step === 3 && <ReviewAndSubmitStep data={dataForm} onBack={(e) => { stopEventPropagation(e); setStep(2) }} />}
					</Form>
				</div>
			</div>
			{isLoading && <Spinner showAdvice={true} />}
		</main>
	)
}

export default CreateGame